//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as EntitySelection from '@/components/Modals/Default/EntitySelector/Presets'
import api from '@/api/v3'
import {
  teamsStore,
  uiStore,
  billingStore,
} from '@/store'
import NoAvailablePlacesWarning from './NoAvailablePlacesWarning.vue'

export default {
  name: 'AddContact',
  components: {
    TabByPhone: () => import('./TabByPhone'),
    TabDirectImport: () => import('./TabDirectImport'),
    TabOtherTeams: () => import('./TabOtherTeams'),
    TabQrCode: () => import('./TabQRCode'),
    IconHelp: () => import('@tada/icons/dist/IconHelpCircleM.vue'),
    NoAvailablePlacesWarning,
  },
  data () {
    return {
      allowAddingByPhone: false,
      inviteType: null,
      contactsDirectImport: [],
      contactsOtherTeams: [],
      contactsByPhone: [],
      contactsFromNodes: [],
      addingContacts: false,
    }
  },
  async created () {
    this.allowAddingByPhone = window.FEATURES.auth_by_sms
    this.allowAddingFromNodes = window.FEATURES.multi_nodes
    this.inviteType = window.FEATURES.auth_by_sms
      ? 'byPhone'
      : 'otherTeams'
    const { account, teamAccount } = await api.loadMe()
    billingStore.actions.setBillingAccount(account)
    await billingStore.actions.setBillingTeamAccount(teamAccount)
  },
  beforeDestroy () {
    delete window['@@EntitySelector']
  },
  computed: {
    currentTeam () {
      return teamsStore.getters.currentTeam
    },
    headerCaption () {
      return teamsStore.getters.isSingleGroupTeam()
        ? this.$t('modals.AddContact.captionSingleGroupTeam')
        : this.$t('modals.AddContact.caption')
    },
    headerTooltipText () {
      let busyDeclension = ''
      let availableDeclension = ''

      if (this.totalBusySlots > 4 || this.totalBusySlots === '0') {
        busyDeclension = this.$t('glossary.workingPlace_plural_R')
      } else if (this.totalBusySlots > 1) {
        busyDeclension = this.$t('glossary.workingPlace_plural_D')
      } else busyDeclension = this.$t('glossary.workingPlace')

      if (this.workplaceSlotsCount > 2 || this.workplaceSlotsCount === '0') {
        availableDeclension = this.$t('glossary.free_plural_R')
      } else if (this.workplaceSlotsCount > 0) availableDeclension = this.$t('glossary.free_male_R')

      return {
        text: this.isSlotsFilled
          ? this.$t('modals.AddContact.noAvailableSlotsTooltip')
          : this.$t('modals.AddContact.availableSlotsTooltip', {
            busy: `${this.totalBusySlots} ${busyDeclension}`,
            available: `${this.workplaceSlotsCount} ${availableDeclension}`,
          }),
      }
    },
    tabs () {
      const byPhone = {
        name: 'byPhone',
        title: this.$t('modals.AddContact.byPhone'),
      }
      const otherTeams = {
        name: 'otherTeams',
        title: this.$t('modals.AddContact.otherTeams'),
      }
      const QRCode = {
        name: 'QRCode',
        title: this.$t('modals.AddContact.QRCode'),
      }
      const directImport = {
        name: 'directImport',
        title: this.$t('modals.AddContact.directImport.title'),
      }
      const fromNodes = {
        name: 'fromNodes',
        title: this.$t('modals.AddContact.fromNodes.title'),
      }

      const tabs = [
        ...(this.allowAddingByPhone ? [byPhone] : []),
        otherTeams,
        QRCode,
        ...(this.allowAddingByPhone ? [directImport] : []),
        ...(this.allowAddingFromNodes ? [fromNodes] : []),
      ]
      return tabs
    },
    contactsToAdd () {
      switch (this.inviteType) {
        case 'directImport':
          return this.contactsDirectImport
        case 'fromNodes':
          return this.contactsFromNodes
        case 'otherTeams':
          return this.contactsOtherTeams
        case 'byPhone':
          return this.contactsByPhone
        default:
          return []
      }
    },
    isBillingEnabled () {
      return window.FEATURES.billing || false
    },
    isAvailableUsersSlots () {
      return this.isBillingEnabled ? (billingStore.state.teamAccount.emptyWorkplaceCount > 0) : true
    },
    isAddingDisabled () {
      return this.isBillingEnabled ? (this.totalBusySlots > billingStore.state.teamAccount.workplaceCount) : false
    },
    isSlotsFilled () {
      return this.isBillingEnabled ? (this.totalBusySlots >= billingStore.state.teamAccount.workplaceCount) : false
    },
    workplaceSlotsCount () {
      return this.isBillingEnabled ? billingStore.state.teamAccount.workplaceCount : 0
    },
    totalBusySlots () {
      return this.isBillingEnabled ? (billingStore.state.teamAccount.occupiedWorkplaceCount + this.contactsToAdd.length) : 0
    },
  },
  methods: {
    async onCommit () {
      this.addingContacts = true
      switch (this.inviteType) {
        case 'directImport':
          await this.$refs.directImport.importContacts()
          break
        case 'fromNodes':
          await this.$refs.fromNodes.commit() && this.close()
          break
        case 'otherTeams':
          await this.$refs.otherTeams.commit() && this.close()
          break
        case 'byPhone':
          await this.commitByPhone()
          break
      }
      this.addingContacts = false
    },
    async commitByPhone () {
      const successlyAddedContacts = await this.$refs.byPhone.commit()

      if (this.$refs.byPhone.error) return

      const selectEntity = window['@@EntitySelector'] && successlyAddedContacts.length > 0 && successlyAddedContacts[successlyAddedContacts.length - 1]
      this.close()

      // Если это модальное окно было открыто из модального окна "EntitySelector", то возвращаемся в него
      if (selectEntity) {
        this.$nextTick(() => {
          EntitySelection.openSelectionOfTeamMembers({
            preselected: [selectEntity.jid],
          })
        })
      }
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
