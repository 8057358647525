//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createMessage } from '@/api/v3/DataGenerator'

import SimpleReplyArea from './SimpleReplyArea'
import MessagesContainer from './MessagesContainer'
import DestinationPicker from './DestinationPicker'

import { teamsStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'

export default {
  name: 'RedirectMessagesModal',
  components: {
    SimpleReplyArea,
    MessagesContainer,
    DestinationPicker,
  },
  data () {
    return {
      linkedMessages: [],
      chats: [],
    }
  },
  computed: {
    header () {
      let { length } = this.linkedMessages
      length = length || 1

      return this.$t('modals.RedirectMessagesModal.forward') + ' ' + this.$tc('glossary.message_count', length)
    },
  },
  created () {
    this.isMultiselectDisabled = true
  },
  methods: {
    redirect (chats) {
      chats = Array.isArray(chats) ? chats : this.chats

      if (chats.length === 0) return

      const { dispatch, getters } = this.$store

      for (const index in chats) {
        const chatId = chats[index]
        if (!chatId) return

        const createMessageModel = (
          text,
          linkedMessages = [],
          uploads = [],
        ) => createMessage({
          sender: getters.getUserId,
          recipient: chatId,
          text,
          linkedMessages,
          uploads,
        })

        let text = ''
        let messages = null

        const { replyArea } = this.$refs
        if (replyArea) {
          const content = replyArea.getContent()

          if (Array.isArray(content) && content.length > 0) {
            messages = content.map(text => createMessageModel(text))
            messages[messages.length - 1].linkedMessages = this.linkedMessages
          } else {
            text = typeof content === 'string' ? content : ''
          }
        }

        if (!messages) {
          messages = [createMessageModel(text, this.linkedMessages)]
        }

        messages.forEach(message => {
          dispatch(actionTypes.SOCKET_SEND_MESSAGE, { message, chatId })
        })
      }
      this.$router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: chats[0],
        },
      })
      this.$parent.close()
    },
  },
}
