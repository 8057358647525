//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { highlightMatches, preventHtmlTags, parseEmoji } from '@/utils'
import BaseContactAvatar from '@/components/UI/BaseEntityAvatar.vue'
import { contactsStore, groupsStore } from '@/store'
import { Contact, Chat } from '@tada-team/tdproto-ts'

export default {
  name: 'EntityRow',
  components: {
    BaseContactAvatar,
  },
  props: {
    source: {
      type: [Object, String],
      required: true,
    },
    filter: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 56,
    },
    padding: {
      type: Number,
      default: 16,
    },
    avatarSize: {
      type: Number,
      default: 42,
    },
    avatarIndicatorSize: {
      type: Number,
      default: 13,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    entity () {
      const { getters } = this.$store
      const result = typeof this.source === 'string' ? getters.entity(this.source) : this.source
      return result || {}
    },
    icon () {
      const entity = this.entity
      if (entity instanceof Contact) { return contactsStore.getters.contactIcon(entity.jid) } else if (entity instanceof Chat) {
        return groupsStore.getters.icon(entity.jid, true)
      }
      return entity.icon
    },
    name () {
      return parseEmoji(highlightMatches(preventHtmlTags(this.entity.displayName), this.filter))
    },
    onlineType () {
      const { jid, isGroup } = this.entity
      if (isGroup) return 'none'

      return contactsStore.getters.contactOnlineType(jid)
    },
    isAfk () {
      const { jid, isGroup } = this.entity
      if (isGroup) return 'none'

      return contactsStore.getters.contactIsAfk(jid)
    },
  },
  created () {
    this.$options.avatarSize = this.avatarSize || (this.height - Math.round(this.height / 8) * 2)
    this.$options.padding = this.padding || (Math.round(this.height / 7) * 2)
  },
}
