//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch } from '@/utils'
import SearchColoredInput from '@/components/UI/SearchColoredInput'
import ScrollableArea from '@/components/UI/ScrollableArea'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

import GroupInstanceRow from './GroupInstanceRow'
import { publicGroupsStore, uiStore } from '@/store'

export default {
  name: 'GroupJoin',
  components: {
    'search-input': SearchColoredInput,
    'scrollable-area': ScrollableArea,
    BaseSpinner,

    GroupInstanceRow,
  },
  data () {
    return {
      filter: '',
      listHeight: 0,
    }
  },
  computed: {
    list () {
      return publicGroupsStore.state.list
    },
    filteredList () {
      return this.filter ? this.list.filter(group => isMatch(group.displayName, this.filter)) : this.list
    },
  },
  watch: {
    list: {
      handler (value, previousValue) {
        if (!value) {
          if (previousValue) publicGroupsStore.actions.loadAll()
          return
        }

        if (value.length > this.thresholdRows) {
          this.listHeight = window.innerHeight - (160 + 108)
          if (this.listHeight < 0) {
            this.listHeight = this.rowHeight * this.thresholdRows
          }
        }
      },
      immediate: true,
    },
  },
  created () {
    this.thresholdRows = 6
    this.rowHeight = 56

    this.listHeight = this.rowHeight * this.thresholdRows

    publicGroupsStore.actions.loadAll()
  },
  beforeDestroy () {
    const id = this.$store.getters.currentChat
    if (!publicGroupsStore.getters.exists(id)) publicGroupsStore.actions.flush()
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
  },
}
