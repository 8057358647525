var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entity-row o-flex-row",class:{
    'entity-row--active': _vm.active,
    'entity-row--deleted': _vm.entity.isArchive,
  },style:({
    height: (_vm.height + "px"),
    padding: ("0 " + (_vm.$options.padding) + "px")
  }),on:{"click":function($event){return _vm.$emit('click', _vm.entity.jid)}}},[_c('BaseContactAvatar',{attrs:{"id":_vm.entity.jid,"size":(_vm.avatarSize + "px"),"show-online-indicator":"","indicator-size":(_vm.avatarIndicatorSize + "px")}}),_c('div',{staticClass:"entity-row__caption o-flex-item-shrinkable o-text-cutter",style:({
      marginLeft: ((_vm.$options.padding) + "px")
    }),domProps:{"innerHTML":_vm._s(_vm.name)}}),_c('div',{staticClass:"entity-row__options o-flex-snuggle-right o-flex-row"},[_vm._t("options",null,{"isActive":_vm.active})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }