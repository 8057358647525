//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sectionsStore, teamsStore } from '@/store'
import { format } from 'quasar'
import { TdInputSelect, TdInput, TdIconBtn } from 'td-ui'

const { capitalize } = format

export default {
  name: 'ContactRow',
  components: {
    'close-icon': () => import('@tada/icons/dist/IconXCircleM.vue'),
    TdInput,
    TdInputSelect,
    TdIconBtn,
  },
  props: {
    sections: Array,
    single: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      // bodgy bodge for our z-index values vs quasar default ones
      popupContentStyle: {
        zIndex: 9999,
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    }
  },
  computed: {
    familyNameInput () {
      return {
        model: 'surname',
        error: this.error.family_name,
        placeholder: this.$t('modals.AddContact.lastName'),
      }
    },
    firstNameInput () {
      return {
        model: 'name',
        error: this.error.given_name,
        placeholder: this.$t('modals.AddContact.firstName'),
      }
    },
    patronymicNameInput () {
      return {
        model: 'patronymic',
        error: this.error.patronymic,
        placeholder: this.$t('modals.AddContact.patronymicName'),
      }
    },
    textInputs () {
      const nameTypes = {
        family_name: this.familyNameInput,
        given_name: this.firstNameInput,
        patronymic: this.patronymicNameInput,
      }

      return teamsStore.getters.currentTeamUserFields.map(field => nameTypes[field])
    },
    error () {
      const noErrorObject = {
        phone: null,
        family_name: null,
        given_name: null,
        patronymic: null,
      }
      return this.data._error || noErrorObject
    },
    alreadyHandled () {
      return this.data._success
    },
    accessLevels () {
      return [
        { id: 'guest', name: capitalize(this.$t('modals.EditContact.guest')) },
        { id: 'member', name: capitalize(this.$t('modals.EditContact.member')) },
        { id: 'admin', name: capitalize(this.$t('modals.EditContact.admin')) },
        { id: 'owner', name: capitalize(this.$t('modals.EditContact.owner')) },
      ]
    },
    availableLevels () {
      return this.accessLevels.filter(
        item => this.changeableStatuses.indexOf(item.id) !== -1,
      )
    },
    changeableStatuses () {
      return teamsStore.getters.currentTeam.changeableStatuses
    },
    isPhoneValid () {
      return this.data.tel.length > 1 ? /^\+?[1-9][0-9]{7,14}$/.test(this.data.tel) : true
    },
  },
  created () {
    this.$watch(vm => vm.data.tel, (value, previousValue) => {
      if (previousValue || !value) return

      this.$emit('start-phone-type')
    })
  },
  methods: {
    clearSectionSelector () {
      this.data.section = null
      this.$refs.sectionSelector.blur()
    },
    options () {
      if (!this.sections) return []

      return [{
        key: 'appearance',
        name: 'none',
        variants: [...this.sections, { uid: null, name: this.$t('modals.AddContact.noSection') }],
        variantName: 'name',
        variantKey: 'uid',
        maxVariants: 5,
        selectedIndex: this.sections.indexOf(this.data.section),
        action: uid => {
          // TODO: change || to ??
          this.data.section = uid ? sectionsStore.getters.section(uid) || this.sections[0] : null
        },
      }]
    },
  },
}
