//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch } from '@/utils'

import SearchInput from './SearchInput'
import EntitySectionList from './EntitySectionList'
import TagList from './TagList'

import KeyEnter from './Keys/KeyEnter'

import { contactsStore, teamsStore } from '@/store'

export default {
  name: 'ContactsPicker',
  components: {
    SearchInput,
    EntitySectionList,
    KeyEnter,
    TagList,
  },
  props: {
    startData: {
      type: Array,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    source: {
      type: Array,
      default: null,
    },
    sections: {
      type: Array,
      default: null,
    },
    maxDisplay: {
      type: Number,
      default: -1,
    },
    error: String,
    filter: Function,
    dropdownTop: Boolean,
  },
  data () {
    return {
      contactName: '',
      selected: 0,

      picked: [],
      focused: false,
    }
  },
  computed: {
    errorMessage () {
      return this.picked.length > 0 ? '' : this.error
    },
    warning () {
      if (!this.computedSections.length === 0) return true

      let amount = 0
      for (const { entities } of this.computedSections) {
        amount += entities.length
      }
      return amount === 0
    },
    computedSections () {
      if (this.sections) {
        return this.sections.map(section => Object.assign({}, section, {
          entities: this.filterEntities(section.entities, section.name),
        }))
      }

      return [{
        name: 'Контакты',
        entities: this.filterEntities(this.source || contactsStore.getters.contactList),
      }]
    },
  },
  created () {
    this.picked = this.startData || []
    this.staticData = [...this.picked]
  },
  methods: {
    filterEntities (entities, sectionName) {
      if (!entities) return []

      teamsStore.getters.currentTeam.hideArchivedUsers && (entities = entities.filter(contact => !contact.isArchive))

      let result = entities
        .filter(contact =>
          this.picked.indexOf(contact) < 0 &&
                        (isMatch(contact.displayName, this.contactName) || isMatch(sectionName, this.contactName)),
        )
        .sort((a, b) => a.displayName > b.displayName ? 1 : -1)

      if (this.filter) {
        result = result.filter(this.filter)
      }

      return result
    },
    setupSelectedData (data) {
      this.selectedData = data
    },
    navigate (event) {
      const { key } = event
      if (key !== 'ArrowUp' && key !== 'ArrowDown') return

      const dir = key === 'ArrowUp' ? -1 : 1
      this.selected += dir
    },
    clickContact (item) {
      const index = this.picked.indexOf(item)
      index >= 0 && this.picked.splice(index, 1)

      this.$emit('input', this.picked)
    },
    pickContact (item) {
      item ||= this.selectedData && this.computedSections[this.selectedData.section].entities[this.selectedData.index]
      if (!item) return

      this.picked.push(item)
      this.contactName = ''

      this.$emit('input', this.picked)
    },
    onFocusChanged (value) {
      this.focused = value
    },
  },
}
