//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './styles.scss'

import api from '@/api/v3'

import { mapGetters } from 'vuex'
import NameInput from '../_CommonUI/NameInput'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

import {
  Events,
  DialogEventBus,
} from '@/components/Modals/XModals/DialogEventBus'

import AvatarSelector from './AvatarSelector.vue'
import {
  loginStore,
  contactsStore,
  teamsStore,
  uiStore,
  sectionsStore,
} from '@/store'

export default {
  name: 'EditProfile',
  components: {
    'name-input': NameInput,
    BaseSpinner,
    'avatar-selector': AvatarSelector,
  },
  data () {
    return {
      nameInputs: {
        familyName: '',
        givenName: '',
        patronymic: '',
      },
      role: '',
      phone: '',
      email: '',

      selectedSections: [],

      icon: '',
      iconBlob: undefined,

      isInProgress: false,
      error: null,
      errorKeys: {},
    }
  },
  computed: {
    ...mapGetters(['profile']),
    currentModalPayload () {
      return uiStore.getters.currentModalPayload
    },
    textInputs () {
      const nameTypes = {
        family_name: this.familyNameInput,
        given_name: this.firstNameInput,
        patronymic: this.patronymicNameInput,
      }

      return teamsStore.getters.currentTeamUserFields
        .filter(field => this.isChangeable(field))
        .map(field => nameTypes[field])
    },
    familyNameInput () {
      return {
        model: 'familyName',
        error: this.errorKeys.family_name,
        placeholder: this.$t('modals.UserProfile.lastName'),
      }
    },
    firstNameInput () {
      return {
        model: 'givenName',
        error: this.errorKeys.given_name,
        placeholder: this.$t('modals.UserProfile.name'),
      }
    },
    patronymicNameInput () {
      return {
        model: 'patronymic',
        error: this.errorKeys.patronymic,
        placeholder: this.$t('modals.UserProfile.patronymicName'),
      }
    },
    canChangeAvatar () {
      return this.isChangeable('icons')
    },
    canChangeRole () {
      return this.isChangeable('role')
    },
    canChangePhone () {
      return this.isChangeable('contact_phone')
    },
    canChangeEmail () {
      return this.isChangeable('contact_email')
    },
    canChangeFirstName () {
      return this.isChangeable('given_name')
    },
    canChangeFamilyName () {
      return this.isChangeable('family_name')
    },
    canChangeMiddleName () {
      return this.usePatronymic && this.isChangeable('patronymic')
    },
    canNotChangeProfile () {
      return (
        !this.canChangeFirstName &&
        !this.canChangeFamilyName &&
        !this.canChangeRole &&
        !this.canChangePhone &&
        !this.canChangeEmail &&
        !this.canChangeAvatar &&
        !this.canChangeMiddleName
      )
    },
    sections () {
      const preparedSections = Object.values(sectionsStore.state.direct).map(
        sectionObj => {
          return {
            uid: sectionObj.uid,
            name: sectionObj.name,
          }
        },
      )
      return preparedSections
    },
  },
  watch: {
    error (value) {
      this.isInProgress = !value
    },
  },
  created () {
    this.contact =
      contactsStore.getters.contact(this.currentModalPayload) || this.profile
    this.jid = this.contact.jid

    // !this.canChangeFirstName && !this.canChangeLastName && !this.canChangeRole && !this.canChangePhone && !this.canChangeEmail && !this.canChangeAvatar && (!this.usePatronymic || !this.canChangeMiddleName)
    if (this.canNotChangeProfile) {
      this.isInProgress = true
      this.close()
    }

    this.nameInputs.familyName = this.contact.familyName
    this.nameInputs.givenName = this.contact.givenName
    this.nameInputs.patronymic = this.contact.patronymic
    this.phone = this.contact.contactPhone
    this.email = this.contact.contactEmail
    this.role = this.contact.role
    this.icon = contactsStore.getters.contactIcon(this.contact.jid)
    this.selectedSections = contactsStore.getters.contact(this.contact.jid).sections.map(uid => {
      return {
        uid,
        name: sectionsStore.getters.sectionNameByUid(uid),
      }
    })
  },
  mounted () {
    DialogEventBus.$on(Events.OPEN_DIALOG, ({ instance, data }) => {
      const { name: previousInstance } = this.$options
      uiStore.actions.showModal({
        instance,
        payload: { previousInstance, data },
      })
    })
  },
  beforeDestroy () {
    DialogEventBus.$off()
  },
  methods: {
    isChangeable (field) {
      return this.profile?.changeableFields?.includes(field) ?? false
    },
    close () {
      loginStore.actions.setBadProfileStatus(false)
      uiStore.actions.hideModal()
    },
    async commit () {
      this.error = ''
      this.errorKeys = {}

      let error = await this.updateData()
      if (error) return (this.error = error)

      error = await this.updateIcon()
      if (error) return (this.error = error)

      this.close()
    },
    async updateData () {
      const data = {}
      data.given_name = this.nameInputs.givenName
      data.family_name = this.nameInputs.familyName
      data.patronymic = this.nameInputs.patronymic
      data.role = this.role
      data.contact_phone = this.phone
      data.contact_email = this.email
      data.sections = this.selectedSections
        ? this.selectedSections.map(sectionObj => sectionObj.uid)
        : []

      try {
        await contactsStore.actions.editContact({
          jid: this.jid,
          params: data,
        })
      } catch (e) {
        let { error, details } = e
        if (details) {
          this.errorKeys = details
          Object.keys(details).some(key => {
            return (error = details[key])
          })
        }
        return error || this.$t('modals.UserProfile.saveErr')
      }
    },
    async updateIcon () {
      if (this.iconBlob === undefined) return null

      if (this.iconBlob) {
        try {
          await api.contacts.setIcon(this.jid, this.iconBlob)
        } catch (e) {
          return this.$t('modals.UserProfile.uploadAvatarErr')
        }
        return null
      } else {
        try {
          await api.contacts.deleteIcon(this.jid)
        } catch (e) {
          return this.$t('modals.UserProfile.deleteAvatarErr')
        }
      }

      return null
    },
  },
}
