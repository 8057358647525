//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './styles.scss'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import FileUploader from './FileUploader'
import AllMembers from './AllMembers'
import EntitySelector from './EntitySelector'
import TeamSettingsModal from './TeamSettings'
import TeamCreation from './TeamCreation'
import GroupForm from './GroupForm'
import GroupJoin from './GroupJoin'
import AvatarCropper from './AvatarCropper'
import Dialog from './Dialog'
import AddContact from './AddContact'
import MemoryUsage from './MemoryUsage'
import EntityGenericList from './EntityGenericList'
import EditProfile from './EditProfile'
import RedirectMessagesModal from './RedirectMessages'
import SectionModal from './SectionModal'
import EditContact from './EditProfile/Contact'
import EditSection from './EditSection'
import CalendarIntegration from './CalendarIntegration'

import CountryCodeSelector from './CountryCodeSelector'
import { isEscape } from '@/utils/KeyUtils'
import { uiStore } from '@/store'

const closableByMaskClick = {
  'all-members': true,
}

export default {
  name: 'DefaultModalWrapper',
  components: {
    AddContact,
    AllMembers,
    AutodeleteSettings: () => import('./AutodeleteSettings'),
    AvatarCropper,
    CalendarIntegration,
    CountryCodeSelector,
    Dialog,
    EditContact,
    EditProfile,
    EditSection,
    EntityGenericList,
    EntitySelector,
    ExportModal: () => import('./ExportModal'),
    FileUploader,
    GroupForm,
    GroupJoin,
    ImportTasks: () => import('./ImportTasks'),
    IntegrationHelp: () => import('./IntegrationHelp'),
    IntegrationManagement: () => import('./IntegrationManagement'),
    Integrations: () => import('./Integrations'),
    MemoryUsage,
    MobileApps: () => import('./MobileApps'),
    MobileLogin: () => import('./MobileLogin'),
    NewTask: () => import('./NewTask'),
    RedirectMessagesModal,
    SectionModal,
    TasksColorsRules: () => import('./TasksColorsRules'),
    TeamCreation,
    TeamSettingsModal,
    TeamStatistic: () => import('./TeamStatistic'),
    UnblockMicModal: () => import('@/components/Chat/AudioMessages/UnblockMicModal'),

    close: createSVGComponent({ icon: 'close', size: 20 }),
  },
  props: {
    standalone: {
      type: Boolean,
      default: false,
    },
    closeByOutsideClick: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentModal () {
      return uiStore.getters.currentModal
    },
    isExtended () {
      return this.extendedModals.indexOf(this.currentModal) >= 0
    },
    isDarken () {
      return this.isExtended || (this.previousModal && this.extendedModals.indexOf(this.previousModal) >= 0)
    },
  },
  watch: {
    currentModal (value, previousModal) {
      this.previousModal = previousModal
    },
  },
  created () {
    this.subModals = [
      'avatar-cropper',
      'dialog-window',
      'IntegrationManagement',
    ]
    this.extendedModals = ['TeamCreation']
    this.previousModal = null

    if (this.standalone) {
      this.escapeKeyPressed = e => { isEscape(e) && this.close() }
      window.addEventListener('keydown', this.escapeKeyPressed) // use keydown for fixing closing modal by closing file dialog with esc
    }
  },
  mounted () {
    if (this.closeByOutsideClick) {
      const { container } = this.$refs
      container && container.addEventListener('click', event => {
        event.target === event.currentTarget && this.close()
      })
    }

    if (this.standalone) {
      this.$nextTick(() => {
        const input = this.$el.querySelector('input[type="text"]')
        if (!input || input.getAttribute('data-autofocus-disabled') === 'true') return
        input.focus()
      })
    }
  },
  beforeDestroy () {
    if (this.standalone) {
      window.removeEventListener('keydown', this.escapeKeyPressed)
    }
  },
  methods: {
    closeOnMaskClick () {
      if (closableByMaskClick[this.currentModal]) {
        this.close()
      }
    },
    close () {
      const { activeModal } = this.$refs
      const closeFunction = activeModal.close

      if (closeFunction) {
        closeFunction()
        return
      }

      uiStore.actions.hideModal()
    },
  },
}
