var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-view o-flex-column o-flex-column--center",on:{"click":function($event){$event.stopPropagation();return _vm.handleViewClick.apply(null, arguments)}}},[(_vm.maxImageWidth && _vm.maxImageHeight)?_c('img',{ref:"subject",staticClass:"image-view__subject o-no-select",class:{
      'image-view__subject--movable': _vm.moveState === 1,
      'image-view__subject--moving': _vm.moveState === 2
    },style:({
      opacity: _vm.ready ? '1' : '0',
      width: ((_vm.zoomWidth || _vm.width) + "px"),
      height: ((_vm.zoomHeight || _vm.height) + "px"),
      transform: ("translate(" + _vm.x + "px, " + _vm.y + "px) rotate(" + _vm.rotate + "deg)")
    }),attrs:{"alt":_vm.file.name,"src":_vm.file.mediaURL || _vm.file.content},on:{"load":_vm.handleImageLoad,"dragstart":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}}):_vm._e(),(!_vm.ready)?_c('BaseSpinner',{attrs:{"color":"base-brand-light"}}):_vm._e(),(_vm.copyLoading)?_c('div',{staticClass:"image-view__controls copying o-flex-row"},[_vm._v(" "+_vm._s(_vm.$t('common.loading'))+" ")]):_c('div',{staticClass:"image-view__controls o-flex-row"},[_c('zoom-in',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomImage(1)}}}),_c('zoom-out',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomImage(-1)}}}),_c('rotate-cw',{on:{"click":function($event){$event.stopPropagation();return _vm.rotateImage(-90)}}}),_c('rotate-ccw',{on:{"click":function($event){$event.stopPropagation();return _vm.rotateImage(90)}}}),_c('div',{staticStyle:{"width":"24px","height":"24px"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpandMode.apply(null, arguments)}}},[_c(_vm.fitStateIcon,{tag:"component"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }