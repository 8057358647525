//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultLogger } from '@/loggers'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'YTVideoView',
  components: {
    BaseSpinner,
  },
  props: ['file', 'fullscreen'],
  data () {
    return {
      isReady: false,
    }
  },
  async created () {
    window.onYouTubeIframeAPIReady = () => {
      this.isReady = true
      try {
        const height = 360
        const width = 640
        this.player = new window.YT.Player('yt-video', {
          height: '' + height,
          width: '' + width,
          videoId: this.file.youtubeId,
          events: {
            onReady: this.onPlayerReady,
          },
        })
      } catch (e) {
        defaultLogger.warn('[YTVideo.onYouTubeIframeAPIReady]', e)
      }
    }

    try {
      const result = await this.loadYoutubeAPI()
      if (result.alreadyLoaded) {
        window.onYouTubeIframeAPIReady()
        return
      } // else - функция onYouTubeIframeAPIReady будет вызвана автоматически
    } catch (e) {
      defaultLogger.warn('[YTVideo] Load YT API script', e)
    }
  },
  beforeDestroy () {
    this.player && this.player.destroy()
    delete window.onYouTubeIframeAPIReady
  },
  methods: {
    onPlayerReady (event) {
      event.target.playVideo()
    },
    async loadYoutubeAPI () {
      return new Promise((resolve, reject) => {
        const scriptId = 'yt-player-script'
        let tag = document.getElementById(scriptId)
        if (tag) return resolve({ alreadyLoaded: true })

        tag = document.createElement('script')
        tag.setAttribute('id', scriptId)
        tag.onload = resolve
        tag.onerror = reject
        tag.src = 'https://www.youtube.com/iframe_api'

        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag && firstScriptTag.parentNode && firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      })
    },
    handleViewClick (event) {
      const { currentTarget, target } = event
      if (currentTarget !== target) return

      const closeFunction = this.$parent.close
      closeFunction && closeFunction()
    },
  },
}
