//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

export default {
  name: 'SwitchButton',
  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    left: {
      type: Boolean,
      default: true,
    },
    topMargin: {
      type: Number,
      default: 54,
    },
  },
  computed: {
    visible () {
      return this.left ? (this.value > 0) : (this.value < this.total - 1)
    },
  },
  created () {
    const margin = 10

    const borderSide = this.left ? 'right' : 'left'

    this.style = {
      [this.left ? 'left' : 'right']: '0',
      top: `${this.topMargin + margin}px`,
      bottom: `${margin}px`,

      [`border-top-${borderSide}-radius`]: '8px',
      [`border-bottom-${borderSide}-radius`]: '8px',
    }

    this.pointerIcon = createRawSVGComponent({ icon: 'next' })
    this.pointerIconStyle = this.left && { transform: 'rotateZ(-180deg)' }
  },
  methods: {
    handleClick () {
      const direction = this.left ? -1 : 1
      const index = Math.max(0, Math.min(this.total - 1, this.value + direction))
      this.$emit('input', index)
    },
  },
}
