//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { transformEntityName } from '@/utils'
import { insertAfter } from '@/utils/DOM'
import { uiStore } from '@/store'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'DialogWindow',
  components: {
    BaseSpinner,
  },
  data () {
    return {
      error: null,
      isInProgress: false,
    }
  },
  watch: {
    error (value) {
      this.isInProgress = !value
    },
  },
  created () {
    const params = uiStore.getters.currentModalPayload

    const defaultValues = {
      caption: '',
      icon: null,
      content: '',
      elementShowcase: null,
      formatData: null,
      acceptLabel: this.$t('modals.approveBtn'),
      acceptCallback: null,
      acceptError: '',
      rejectLabel: this.$t('modals.cancelBtn'),
      rejectCallback: null,
    }

    Object.assign(this.$options, defaultValues, params)

    this.formatContent()
  },
  mounted () {
    let { elementShowcase } = this.$options
    if (elementShowcase && elementShowcase instanceof HTMLElement) {
      if (elementShowcase.parentNode) {
        elementShowcase = elementShowcase.cloneNode(true)
      }

      elementShowcase.style.pointerEvents = 'none'
      elementShowcase.style.marginTop = '10px'

      const { content } = this.$refs
      content && insertAfter(elementShowcase, content)
    }
  },
  methods: {
    async accept () {
      const { acceptCallback } = this.$options
      if (!acceptCallback) return this.close()

      this.error = ''
      try {
        await acceptCallback()
        this.close()
      } catch (e) {
        this.error = this.$options.acceptError || JSON.stringify(e)
      }
    },
    close () {
      const { rejectCallback } = this.$options
      rejectCallback && rejectCallback()

      uiStore.actions.hideModal()
    },
    formatContent () {
      const { formatData, content } = this.$options
      if (!formatData) return

      const format = value => `<b>${transformEntityName(value)}</b>`

      this.$options.content = content.replace(/#\[([^`\r\n]+?)\]/g, (match, group) => {
        return format(formatData[group] || '')
      })
    },
  },
}
