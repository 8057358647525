//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'
export default {
  name: 'Modal',
  props: {
    center: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
  },
}
