//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchRequests from '@/components/MiddleColumn/GlobalSearch/SearchRequests'
import SearchInput from '@/components/Modals/Default/_CommonUI/SearchInput'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import ScrollableArea from '@/components/UI/ScrollableArea'
import {
  contactsStore,
  groupsStore,
  tasksStore,
  uiSettingsStore,
} from '@/store'
import {
  getChatType,
  isMatch,
  sortTasks,
  transformEntityName,
} from '@/utils'
import { sortChats } from '@/utils/DataUtils'
import { searchAncestor } from '@/utils/DOM'
import { Contact, Chat } from '@tada-team/tdproto-ts'
import { format } from 'quasar'

const { capitalize } = format

const taskSearchStartFrom = 2 // symbols

export default {
  name: 'RedirectDestinatonPicker',
  components: {
    SearchInput,
    ScrollableArea,

    'mark-icon': createSVGComponent({
      icon: 'mark-only',
      size: 18,
      sizeStorage: 16,
      permanentlyActive: true,
    }),
  },
  props: {
    isMultiselectDisabled: Boolean,
  },
  data () {
    return {
      filter: '',
      currentOption: '',
      selectedChats: {},

      pointerStyle: {},
      chatListHeight: 266,

      requests: null,

      tasksRequestInProgress: false,
      tasksSearchResult: {
        matches: [],
        total: 0,
        hasMore: false,
      },
      seenTasks: [],
    }
  },
  computed: {
    chatList () {
      const { getters } = this.$store

      let contact = []
      let group = []
      const task = this.filter.length < taskSearchStartFrom
        ? this.taskList()
        : this.tasksSearchResult.matches

      const list = [
        ...contactsStore.getters.teamMembers,
        ...groupsStore.getters.list,
      ]

      list
        .filter(entity => entity && entity.canSendMessage && (
          isMatch(entity.displayName, this.filter) ||
          this.isSelected(entity.jid)
        ))
        .forEach(chat => {
          chat instanceof Chat ? group.push(chat) : contact.push(chat)
        })

      contact = sortChats(
        contact,
        uiSettingsStore.getters.sortingChatsType(true),
        getters.unreadFirst,
      )
      group = sortChats(
        group,
        uiSettingsStore.getters.sortingChatsType(false),
        getters.unreadFirst,
      )

      return { contact, group, task }
    },
    sections () {
      const sections = this.currentOption === 'everything'
        ? this.chatList
        : { single: this.chatList[this.currentOption] }

      return Object.fromEntries(
        Object.entries(sections).filter(([name, chats]) => {
          if (name === 'task' && this.tasksRequestInProgress) return true
          return chats.length > 0
        }),
      )
    },
    selectedCount () {
      return this.selectedChats ? Object.keys(this.selectedChats).length : 0
    },
    noMatches () {
      return !Object.keys(this.sections).length
    },
    headerCaption () {
      const prefix = this.$t('modals.RedirectMessagesModal.destinationCaption')
      if (this.isMultiselectDisabled) return prefix

      return this.selectedCount ? `${prefix} (${this.selectedCount})` : prefix
    },
  },
  created () {
    this.options = ['everything', 'contact', 'group', 'task']
    this.requests = new SearchRequests()

    const { getters } = this.$store
    this.currentChat = getters.currentChat
    this.currentChatType = getChatType(this.currentChat)

    this.setCurrentOption(this.options[0])

    this.toggleSelection(this.currentChat)
  },
  beforeDestroy () {
    this.requests && this.resetTasksSearchResult()
  },
  methods: {
    icon (entity) {
      if (entity instanceof Contact) {
        return contactsStore.getters.contactIcon(entity.jid)
      } else if (entity instanceof Chat) {
        return groupsStore.getters.icon(entity.jid, true)
      }
      return entity.icon
    },
    onSearchInput (value) {
      this.resetTasksSearchResult()
      if (value.length >= taskSearchStartFrom) this.taskSearch(value)
    },
    async taskSearch (value) {
      this.tasksRequestInProgress = true
      const result = await this.requests.getTasks(value)

      if (result && result.total > 0) {
        this.tasksSearchResult.matches.push(...result.matches)
        this.tasksSearchResult.total = result.total
        this.tasksSearchResult.hasMore = result.hasMore
      }

      this.tasksRequestInProgress = false
    },
    taskList () {
      let items = Object
        .values(tasksStore.state.data)
        .filter(this.taskListFilter)

      items = sortTasks(items, 'activity')

      if (this.currentChatType === 'task') {
        for (const index in items) {
          if (items[index].jid === this.currentChat) {
            return this.moveCurrentChatAtTop(items, index)
          }
        }
      }

      return items
    },
    taskListFilter (task) {
      if (!task?.canSendMessage) return false

      const match = isMatch(`#${task.num} ${task.title}`, this.filter)
      const selected = this.isSelected(task.jid)

      return match || selected
    },
    moveCurrentChatAtTop (list, index) {
      if (index < 0) return list

      const chat = list[index]

      list.splice(index, 1)
      list.unshift(chat)

      return list
    },
    setCurrentOption (option) {
      const index = option ? this.options.indexOf(option) : -1
      index < 0 && (option = this.options[0])

      this.currentOption = option

      this.$nextTick(() => {
        this.setPointerPosition()

        const { scrollableArea } = this.$refs
        scrollableArea && scrollableArea.scrollTop()
      })
    },
    setPointerPosition () {
      const { list } = this.$refs
      if (!list) {
        this.pointerStyle = {}
        return
      }

      const optionElement = list.querySelector('.options__item--selected')
      const { offsetLeft: left, offsetWidth: width } = optionElement

      this.pointerStyle = { left: `${left}px`, width: `${width}px` }
    },
    getCaption (option) {
      switch (option) {
        case 'everything': return capitalize(this.$t('glossary.everything'))
        case 'contact': return capitalize(this.$t('glossary.contact_plural'))
        case 'group': return capitalize(this.$t('glossary.group_plural'))
        case 'task': return capitalize(this.$t('glossary.task_plural'))
      }
    },
    transformName (chat) {
      return transformEntityName(chat.title || chat.displayName || '')
    },
    isSelected (jid) {
      return !!this.selectedChats[jid]
    },
    toggleSelection (jid) {
      if (this.isMultiselectDisabled) {
        this.selectedChats = {}
      }

      this.isSelected(jid)
        ? this.$delete(this.selectedChats, jid)
        : this.$set(this.selectedChats, jid, 'true')

      this.$emit('input', Object.keys(this.selectedChats))
    },
    searchAncestorFunction (element) {
      if (!element) return false

      return element.classList.contains('list-section__item') || element === this.$el
    },
    handleListClick (event) {
      const { target } = event
      if (!target) return

      const element = searchAncestor(target, this.searchAncestorFunction)
      if (element === this.$el) return

      const jid = element.getAttribute('data-jid')
      if (!jid) return

      if (event.type === 'dblclick') {
        const { redirect } = this.$parent
        if (redirect && typeof redirect === 'function') {
          this.selectedChats = { jid }
          redirect([jid])
        }
        return
      }
      this.toggleSelection(jid)
    },
    handleVisibility (sectionType, chat, visible) {
      if (sectionType === 'task') {
        chat && chat.jid && !this.seenTasks.includes(chat.jid) && this.seenTasks.push(chat.jid)
        visible && this.needToLoadMore() && !this.tasksRequestInProgress && this.taskSearch(this.filter)
      }
    },
    needToLoadMore () {
      if (!this.tasksSearchResult.hasMore) return false

      const numUnseenTasksTotal = this.tasksSearchResult.matches.length - this.seenTasks.length
      const maxUnseenTasks = 5
      return numUnseenTasksTotal <= maxUnseenTasks
    },
    resetTasksSearchResult () {
      this.requests.resetPagination('tasks')
      this.tasksSearchResult = {
        matches: [],
        total: 0,
        hasMore: false,
      }
      this.seenTasks = []
    },
    isShowTasksLoading (sectionType) {
      // Without hasMore, the loader may appear after
      // scrolling to the end of the container and will not be visible.
      // Therefore, let the loader constantly hang there while we have
      // something to download.
      return sectionType === 'task' && (this.tasksRequestInProgress || this.tasksSearchResult.hasMore)
    },
  },
}
