//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { searchAncestorByAttribute } from '@/utils/DOM'

import { isMatch } from '@/utils'
import SearchColoredInput from '@/components/UI/SearchColoredInput'
import ScrollableArea from '@/components/UI/ScrollableArea'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { uiStore } from '@/store'

export default {
  name: 'CountryCodeSelector',
  components: {
    'search-input': SearchColoredInput,
    'scrollable-area': ScrollableArea,
    BaseSpinner,
  },
  data () {
    return {
      filter: '',
      listHeight: 0,
    }
  },
  computed: {
    list () {
      const { getters } = this.$store
      const { list } = getters.currentModalPayload || {}
      return list
    },
    filteredList () {
      if (!this.filter) return this.list

      const predicate = country => {
        const { code, name } = country
        return code.indexOf(this.filter) >= 0 || isMatch(name, this.filter)
      }

      return this.list.filter(predicate)
    },
  },
  watch: {
    list () {
      this.setupListHeight()
    },
  },
  created () {
    this.thresholdRows = 6
    this.rowHeight = 38

    this.listHeight = this.rowHeight * this.thresholdRows

    this.setupListHeight()

    const { getters } = this.$store
    const { callback } = getters.currentModalPayload || {}
    this.callback = callback
  },
  methods: {
    setupListHeight () {
      if (this.list.length > this.thresholdRows) {
        this.listHeight = Math.min(405, window.innerHeight - (160 + 108))
        if (this.listHeight < 0) {
          this.listHeight = this.rowHeight * this.thresholdRows
        }
      }
    },
    handleClick (event) {
      const { target } = event
      if (!target || !this.callback) return

      const item = searchAncestorByAttribute(target, 'data-code')
      if (!item) return

      const name = item.getAttribute('data-name')
      const code = item.getAttribute('data-code')

      this.callback({ name, code })

      this.close()
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
