import api from '@/api/v3'
import { createMessage } from '@/api/v3/DataGenerator'
import { makeXHRequest } from '@/api/v3/xhr'
import { SOCKET_SEND_MESSAGE } from '@/store/actionTypes'
import { JID } from '@tada-team/tdproto-ts'
import axios, { CancelTokenSource } from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { teamsStore, uiStore } from '@/store'

const { CancelToken } = axios

@Component
export default class UploadMixin extends Vue {
  @Action [SOCKET_SEND_MESSAGE]: (params: { chatId: JID, message: TADA.Message }) => void

  @Getter currentChat!: JID
  @Getter getUserId!: JID

  cancelTokenSource?: CancelTokenSource
  fileUniqueKeyLabel = 'uniqueKey'
  isInProgress = false
  loadingStates: Record<string, number> = {}
  xhr?: XMLHttpRequest

  created (): void {
    this.xhr = undefined
  }

  close (): void {
    this.xhr && this.xhr.abort()
    this.cancelTokenSource && this.cancelTokenSource.cancel()
    uiStore.actions.hideModal()
  }

  private promisifyRequest (file: File): Promise<void> {
    const data = new FormData()
    data.append('file', file)

    // TODO: this needs to be in api/v3(4)/ methods
    return new Promise((resolve, reject) => {
      this.xhr = makeXHRequest({
        url: `/api/v4/teams/${teamsStore.state.currentTeamId}/messages/${this.currentChat}/`,
        method: 'POST',
        data,
        resolve,
        reject,
        timeout: 0,
      })
    })
  }

  private setState (key: string, value: number): void {
    this.$set(this.loadingStates, key, value)
  }

  async send (files: File[]): Promise<void> {
    if (files.length === 0 || this.isInProgress) return

    this.isInProgress = true

    const uploads: TADA.Upload[] = []
    const useUploads = window.FEATURES.message_uploads
    // TODO: remove when server fully migrates to new uploads approach
    let uploader: (file: File) => Promise<void>
    if (useUploads) {
      this.cancelTokenSource = CancelToken.source()
      const token = this.cancelTokenSource.token
      uploader = async file => {
        const upload = await api.uploads.create(file, token)
        uploads.push(upload)
        return Promise.resolve()
      }
    } else {
      uploader = this.promisifyRequest
    }

    for (const file of files) {
      /**
       * This is very bad practice to use Object.defineProperty.
       * So, here we are forced to use type casting.
       * @see [Dropzone.vue:133]
       */
      const key = (file as File & { [key: string]: string })[this.fileUniqueKeyLabel]
      if (this.loadingStates[key] === 2) continue

      try {
        this.setState(key, 1)
        await uploader(file)
        this.setState(key, 2)
      } catch (e) {
        this.setState(key, 3)
      }
    }

    // If at least 1 file has not been loaded correctly,
    // then we do not need to close the dialog box.
    Object.keys(this.loadingStates).some(key => {
      const state = this.loadingStates[key]
      if (state === 3) {
        this.isInProgress = false
      }
    })

    if (this.isInProgress && useUploads) {
      const message = createMessage({
        sender: this.getUserId,
        recipient: this.currentChat,
        text: '',
        uploads,
      })

      this[SOCKET_SEND_MESSAGE]({
        chatId: this.currentChat,
        message,
      })
    }

    this.isInProgress && this.close()
  }
}
