//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch } from '@/utils'

import ScrollableArea from '@/components/UI/ScrollableArea'
import SearchColoredInput from '@/components/UI/SearchColoredInput'

import i18n from '@/i18n'

export default {
  name: 'DropdownSelector',
  components: {
    'scrollable-area': ScrollableArea,

    'search-input': SearchColoredInput,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => { return {} },
    },
    placeholder: {
      type: String,
      default: i18n.t('common.dropDownNotSelected'),
    },
    displayField: {
      type: String,
      default: '',
    },
    keyField: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      default: 150,
    },
    maxListLength: {
      type: Number,
      default: 100,
    },
    itemHeight: {
      type: Number,
      default: 25,
    },
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      selected: null,
    }
  },
  computed: {
    filteredData () {
      const list = this.data.filter(item => isMatch(item[this.displayField], this.filter))
      return list.length > this.maxListLength ? list.slice(0, this.maxListLength) : list
    },
    computedHeight () {
      const height = Math.min(this.maxHeight, this.filteredData.length * this.itemHeight)
      return height > 0 ? `${height + 8}px` : 'auto'
    },
  },
  watch: {
    isOpen (value) {
      if (!value) return

      this.$nextTick(() => {
        const { searchInput } = this.$refs
        if (!searchInput) return

        typeof searchInput.focus === 'function' && searchInput.focus()
      })
    },
  },
  created () {
    this.selected = this.value || (this.data.length > 0 && this.data[0])
  },
  mounted () {
    this.handleWindowClick = event => {
      if (!event.target.matches('.dropdown-selector *')) {
        this.isOpen = false
      }
    }
    window.addEventListener('mousedown', this.handleWindowClick)
  },
  beforeDestroy () {
    window.removeEventListener('mousedown', this.handleWindowClick)
  },
  methods: {
    handleItemClick (item) {
      this.selected = item

      this.$emit('input', item)

      this.isOpen = false
    },
    onToggleFocus (focus) {
      !focus && window.setTimeout(() => { this.isOpen = false }, 300)
    },
  },
}
