//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { getAllowedFileExtensions } from '@/utils'

export default {
  name: 'Dropzone',
  components: {
    upload: createSVGComponent({ icon: 'upload', size: 48 }),
  },
  props: {
    addMore: {
      type: Boolean,
      default: false,
    },
    generateKey: {
      type: String,
      default: '',
    },
    value: Array,
  },
  data () {
    return {
      active: false,
      hover: false,
    }
  },
  computed: {
    allowedFileExtension () {
      return getAllowedFileExtensions()
    },
  },
  created () {
    this.counter = 0

    const { maxUploadSizeFeature } = this.$store.getters
    this.maxUploadSizeFeature = maxUploadSizeFeature
    this.limit = Math.floor(maxUploadSizeFeature / 1024 / 1024)

    this.dragging = 0
  },
  methods: {
    dragOver (event) {
      this.active = true

      event.stopPropagation()
      event.preventDefault()
      return false
    },
    dragEnter (event) {
      this.dragging++
      this.active = true

      event.stopPropagation()
      event.preventDefault()
      return false
    },
    dragLeave (event) {
      this.dragging--

      if (this.dragging === 0) {
        this.active = false
      }

      event.stopPropagation()
      event.preventDefault()
      return false
    },
    selectFile (event) {
      const input = event.target
      if (!input) return

      const { files } = input
      if (!files || files.length === 0) return

      this.handleFiles([...files])

      input.value = ''
    },
    drop (event) {
      this.active = false

      const { files } = event.dataTransfer
      this.handleFiles(files)
    },
    handleFiles (fileList) {
      this.$emit('error', null)
      if (fileList.length === 0) return
      fileList = Array.prototype.slice.call(fileList).slice(0, 20)

      const files = fileList.filter(f => f.size <= this.maxUploadSizeFeature)
      const bigFiles = fileList.filter(f => f.size > this.maxUploadSizeFeature)

      if (bigFiles.length) {
        const names = bigFiles.map(f => f.name || '').join(', ')

        const msg =
          bigFiles.length > 1
            ? this.$t('modals.FileUploader.filesOverSize', { names, limit: this.limit })
            : this.$t('modals.FileUploader.fileOverSize', { names, limit: this.limit })

        this.$emit('error', msg)
      }

      let filteredFiles = files
      const { FEATURES } = window

      const blackList = FEATURES.file_extension_blacklist
      const whiteList = FEATURES.file_extension_whitelist
      const notAllowedNames = []
      const allowedFiles = []

      files.forEach(file => {
        const splitFileName = file.name.split('.').reverse()

        // if file without extension
        // Let's skip it
        if (splitFileName.length < 2 || !splitFileName[0]) {
          allowedFiles.push(file)
          return
        }

        // Get the name of the file extension
        const ext = splitFileName[0]

        // If the file is in the black list
        // or if there is a whitelist but the file is not in it
        if ((blackList && blackList.includes(ext)) ||
          (whiteList && whiteList.length > 0 && !whiteList.includes(ext))) {
          notAllowedNames.push(file.name)
        } else {
          allowedFiles.push(file)
        }
      })

      if (notAllowedNames.length > 0) {
        this.$emit('extensionError', notAllowedNames)
      }

      filteredFiles = allowedFiles

      if (this.generateKey) {
        filteredFiles.forEach(file => {
          if (file[this.generateKey]) return

          const { name, size, lastModified } = file

          Object.defineProperty(file, this.generateKey, {
            __proto__: null,
            value: `${this.counter++}_${name}:${size}:${lastModified}`,
          })
        })
      }

      this.$emit('input', this.addMore ? [].concat(this.value, filteredFiles) : filteredFiles)
    },
  },
}
