//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './common.css'
import { Events, DialogEventBus } from './DialogEventBus'

import UniversalYesNo from '../UniversalYesNo'
import AvatarCropper from '../AvatarCropper'

import ScrollableArea from '@/components/UI/ScrollableArea'
import { uiStore } from '@/store'

export default {
  name: 'XModal',
  components: {
    'scrollable-area': ScrollableArea,
    'dialog-content': UniversalYesNo,
    'avatar-cropper': AvatarCropper,
  },
  data () {
    return {
      layerComponent: null,
      layerData: null,
    }
  },
  computed: {
    smallModal () {
      return uiStore.getters.currentMiddleColumnSmall
    },
    from () {
      const payload = uiStore.$store.getters.currentModalPayload

      return payload && payload.from
    },
  },
  mounted () {
    DialogEventBus.$on(Events.OPEN_DIALOG, ({ instance, data }) => {
      this.layerComponent = instance
      this.layerData = data
    })

    DialogEventBus.$on(Events.CLOSE_DIALOG, this.closeLayer)
  },
  beforeDestroy () {
    DialogEventBus.$off()
  },
  methods: {
    closeLayer () {
      this.layerComponent = null
      this.layerData = null
    },
    close () {
      uiStore.actions.hideModal()
    },
    back () {
      uiStore.actions.showModal(this.from)
    },
  },
}
