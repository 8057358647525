//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint no-console: 0 */

export default {
  name: 'ImageCropperOverlay',
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    minSize: {
      type: Number,
      default: 50,
    },
  },
  data () {
    return {
      frameSize: 0,
      frameX: 0,
      frameY: 0,
    }
  },
  created () {
    this.anchorSize = 20
    this.halfAnchor = this.anchorSize / 2

    this.frameSize = Math.min(this.height, this.width) / 2
    this.frameX = this.width / 2 - this.frameSize / 2
    this.frameY = this.height / 2 - this.frameSize / 2
  },
  mounted () {
    this.componentOffset = this.$el.getBoundingClientRect()
  },
  methods: {
    getFrameRectangle () {
      return {
        x: Math.max(this.frameX, 0),
        y: Math.max(this.frameY, 0),
        width: Math.min(this.frameSize, this.width),
        height: Math.min(this.frameSize, this.height),
      }
    },
    mouseDown (e) {
      const { target } = e

      const type = target.getAttribute('data-iteract')
      if (!type) return

      this.componentOffset = this.$el.getBoundingClientRect()
      const box = target.getBoundingClientRect()
      const shiftX = e.pageX - (box.left + window.pageXOffset) + this.componentOffset.left
      const shiftY = e.pageY - (box.top + window.pageYOffset) + this.componentOffset.top

      const normalize = (value, max) => value > max ? max : (value < 0 ? 0 : value)

      let handler = null
      switch (type) {
        case 'frame':
          handler = e => {
            this.frameX = normalize(e.pageX - shiftX, this.width - this.frameSize)
            this.frameY = normalize(e.pageY - shiftY, this.height - this.frameSize)
          }
          break
        case 'nw':
          handler = e => {
            const x = normalize(e.pageX - shiftX + this.halfAnchor, this.width)
            const y = normalize(e.pageY - shiftY + this.halfAnchor, this.height)

            const horizontalSize = this.frameSize + (this.frameX - x)
            const verticalSize = this.frameSize + (this.frameY - y)

            const size = Math.max(horizontalSize, verticalSize)

            if (size >= this.minSize) {
              const x = this.frameX + (this.frameSize - size)
              if (x < 0) return

              const y = this.frameY + (this.frameSize - size)
              if (y < 0) return

              this.frameX = x
              this.frameY = y
              this.frameSize = size
            }
          }
          break
        case 'ne':
          handler = e => {
            const x = normalize(e.pageX - shiftX + this.halfAnchor, this.width)
            const y = normalize(e.pageY - shiftY + this.halfAnchor, this.height)

            const horizontalSize = x - this.frameX
            const verticalSize = this.frameSize + (this.frameY - y)
            const size = Math.max(verticalSize, horizontalSize)

            if (size >= this.minSize && (size + this.frameX) <= this.width) {
              const y = this.frameY + (this.frameSize - size)
              if (y < 0) return

              this.frameY = y
              this.frameSize = size
            }
          }
          break
        case 'se':
          handler = e => {
            const y = normalize(e.pageY - shiftY + this.halfAnchor, this.height)
            const x = normalize(e.pageX - shiftX + this.halfAnchor, this.width)

            const height = y - this.frameY
            const width = x - this.frameX

            const delta = Math.min(height, width)

            if (delta >= this.minSize) this.frameSize = delta
          }
          break
        case 'sw':
          handler = e => {
            const x = normalize(e.pageX - shiftX + this.halfAnchor, this.width)
            const y = normalize(e.pageY - shiftY + this.halfAnchor, this.height)

            const horizontalSize = this.frameSize + (this.frameX - x)
            const verticalSize = y - this.frameY
            const size = Math.max(verticalSize, horizontalSize)

            if (size >= this.minSize && (size + this.frameY) <= this.height) {
              const x = this.frameX + (this.frameSize - size)
              if (x < 0) return

              this.frameX = x
              this.frameSize = size
            }
          }
          break
      }

      if (!handler) return

      this.setCurrentMoveHandler(handler)
      document.addEventListener('mouseup', this.mouseUp)
    },
    mouseUp () {
      this.setCurrentMoveHandler(null)
      document.removeEventListener('mouseup', this.mouseUp)
    },
    setCurrentMoveHandler (handler) {
      if (this.handler) {
        document.removeEventListener('mousemove', this.handler)
        this.handler = null
      }

      if (!handler) return

      this.handler = handler
      document.addEventListener('mousemove', this.handler)
    },
  },
}
