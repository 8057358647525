//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { throttle } from 'quasar'

export default {
  name: 'ModalSearchInput',
  components: {
    'search-icon': createSVGComponent({ icon: 'search', size: 20 }),
  },
  props: {
    value: String,
    placeholder: String,
    delay: Number,
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      focused: false,
    }
  },
  created () {
    this.emitInputValue = value => {
      this.$emit('input', (value || '').trim())
    }

    this.emitInput = this.delay ? throttle(this.emitInputValue, this.delay) : this.emitInputValue
  },
  beforeDestroy () {
    this.emitInput.cancel && this.emitInput.cancel()
  },
  methods: {
    clearInput () {
      this.$emit('input', '')
      this.blur()
    },
    blur () {
      const { input } = this.$refs
      input && input.blur()
    },
    toggleFocus (e) {
      this.$emit('toggleFocus', this.focused = e.type === 'focus')
    },
  },
}
