//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollableAreaWrapper from '@/components/UI/Wrappers/ScrollableAreaWrapper.jsx'

import Dropzone from './Dropzone'
import FileThumbnail from './FileThumbnail'

import UploadMixin from './UploadMixin'
import { uiStore } from '@/store'

export default {
  name: 'FileUploader',
  components: {
    'scrollable-area-wrapper': ScrollableAreaWrapper,

    Dropzone,
    FileThumbnail,
  },
  mixins: [UploadMixin],
  data () {
    return {
      files: [],
      error: null,
      notAllowedNames: null,
    }
  },
  computed: {
    sendButtonCaption () {
      const { length } = this.files
      return length
        ? `${this.$t('modals.sendBtn')} ${length} ${this.$tc('modals.UploadFile.file', length)}`
        : this.$t('modals.sendBtn')
    },
    rowsTaken () {
      const { length } = this.files
      return Math.ceil(length / this.thumbnailsPerRow)
    },
    scrollableAreaHeight () {
      const rows = Math.min(this.rowsTaken, this.maxVisibleRows)
      return (this.thumbnailHeight + this.thumbnailMarginTop) * rows - this.thumbnailMarginTop
    },
    dropzoneHeight () {
      return this.files.length > 0 ? 120 : 200
    },
    padCount () {
      const { length } = this.files
      return (this.rowsTaken * this.thumbnailsPerRow) - length
    },
  },
  created () {
    this.fileUniqueKeyLabel = 'uniqueKey'

    this.dropzoneWidth = 620
    this.thumbnailWidth = 116
    this.thumbnailHeight = 90
    this.thumbnailMarginTop = 10
    this.thumbnailsPerRow = Math.floor(this.dropzoneWidth / this.thumbnailWidth)

    this.maxVisibleRows = 2
  },
  mounted () {
    const files = uiStore.getters.currentModalPayload
    if (files && files.length) {
      const { dropzone } = this.$refs
      if (!dropzone) return

      dropzone.handleFiles(files)
    }

    window.addEventListener('keydown', this.keyPressed)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyPressed)
  },
  methods: {
    keyPressed (e) {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.send(this.files)
      }
    },
  },
}
