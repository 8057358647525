//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'
import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import Tape from './Tape'
import SwitchButton from './SwitchButton'

import Image from './Views/Image'
import Video from './Views/Video'
import YTVideo from './Views/YTVideo'

export default {
  name: 'FileViewer',
  components: {
    download: createRawSVGComponent({ icon: 'gall_downl' }),
    close: createRawSVGComponent({ icon: 'gall_close' }),

    'image-view': Image,
    'video-view': Video,
    'ytvideo-view': YTVideo,

    Tape,
    SwitchButton,
  },
  data () {
    return {
      activeIndex: 0,
      fullscreenView: false,
    }
  },
  computed: {
    payload () {
      return uiStore.getters.currentModalPayload
    },
    files () {
      const { viewerContentList } = this.payload
      return viewerContentList || [this.payload]
    },
    activeFile () {
      return this.files[this.activeIndex] || { name: 'Empty' }
    },
    listMode () {
      return this.files.length > 1
    },
    activeFileDownloadLink () {
      const { content, mediaURL } = this.activeFile
      const url = mediaURL || content

      const isDownloadable = typeof url === 'string' && url.indexOf('http') === 0
      return isDownloadable ? url : ''
    },
  },
  created () {
    const { targetIndex } = this.payload
    this.activeIndex = targetIndex || 0
  },
  mounted () {
    this.keydownHandler = event => {
      if (!this.listMode) return

      const { key } = event

      if (key !== 'ArrowRight' && key !== 'ArrowLeft') return

      const direction = key === 'ArrowRight' ? 1 : -1
      const index = Math.max(0, Math.min(this.files.length - 1, this.activeIndex + direction))
      this.activeIndex = index
    }
    window.addEventListener('keydown', this.keydownHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keydownHandler)
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
    toggleFullscreen (value) {
      this.fullscreenView = value
    },
  },
}
