//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollableArea from '@/components/UI/ScrollableArea'

import Instance from './Instance'

export default {
  name: 'SimpleReplyArea',
  components: {
    ScrollableArea,
  },
  data () {
    return {
      areaHeight: 18,
    }
  },
  mounted () {
    const { editor, scrollableArea } = this.$refs
    if (!editor || !scrollableArea) return

    this.instance = new Instance({
      element: editor,
      placeholder: this.$t('modals.RedirectMessagesModal.placeholder'),
      onTextChange: (delta, oldDelta, source) => {
        if (source !== 'user') return

        const height = Math.max(Math.min(scrollableArea.getContentHeight(true), 50), 18)
        this.areaHeight = height
      },
    })
  },
  beforeDestroy () {
    this.instance && this.instance.destroy()
  },
  methods: {
    getContent () {
      return this.instance ? this.instance.getContent() : ''
    },
  },
}
