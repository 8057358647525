//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'
import { isMatch } from '@/utils'
import SearchColoredInput from '@/components/UI/SearchColoredInput'
import ScrollableArea from '@/components/UI/ScrollableArea'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'EntityGenericList',
  components: {
    'search-input': SearchColoredInput,
    'scrollable-area': ScrollableArea,
    BaseSpinner,
  },
  data () {
    return {
      filter: '',
      listHeight: 0,
      list: null,
    }
  },
  computed: {
    filteredList () {
      return this.filter ? this.list.filter(entity => isMatch(entity.displayName, this.filter)) : this.list
    },
  },
  watch: {
    list () {
      this.recalculateListHeight()
    },
  },
  created () {
    const options = {
      componentRow: null,
      componentRowProps: {},

      dataKey: 'jid',

      caption: this.$t('modals.EntityGenericList.caption'),
      searchPlaceholder: this.$t('modals.EntityGenericList.searchPlaceholder'),
      searchEmptyMessage: this.$t('modals.EntityGenericList.searchEmptyMessage'),

      rejectLabel: this.$t('modals.EntityGenericList.rejectLabel'),

      thresholdRows: 6,
      rowHeight: 56,
      getData: async () => { return [] },
    }

    Object.assign(this.$options, options, uiStore.getters.currentModalPayload || {})

    this.$options.getData().then(r => {
      this.list = r
    })

    this.recalculateListHeight()
  },
  methods: {
    recalculateListHeight () {
      if (this.list?.length > this.$options.thresholdRows) {
        this.listHeight = window.innerHeight - (160 + 108)
        if (this.listHeight < 0) {
          this.listHeight = this.$options.rowHeight * this.$options.thresholdRows
        }
      } else {
        this.listHeight = this.$options.rowHeight * this.$options.thresholdRows
      }
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
