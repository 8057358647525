






































































































































import { defaultLogger } from '@/loggers'
import { Component, Vue } from 'vue-property-decorator'

import { Events, DialogEventBus } from '@/components/Modals/XModals/DialogEventBus'
import AvatarSelector from '@/components/Modals/Default/_CommonUI/AvatarSelector.vue'
import NameInput from '@/components/Modals/Default/_CommonUI/NameInput.vue'
import MemberPicker from '@/components/Modals/Default/MemberPicker/index.vue'
import { uiStore, groupsStore } from '@/store'

import { Chat, GroupMembership } from '@tada-team/tdproto-ts'

import { FormType } from './types'
import { GroupPayload } from '@/store/modules/groups/types'

@Component({
  name: 'GroupForm', // needed because it is used in this.$options
  components: {
    AvatarSelector,
    GroupIcon: () => import('@/components/UI/GroupIcon.vue'),
    MemberPicker,
    NameInput,
  },
})
export default class GroupForm extends Vue {
  type?: FormType
  jid?: string

  group?: Chat

  name = ''
  description = ''
  isPublic = false
  defaultForAll = false
  readOnly = false
  members: string[] = []

  icon?: Blob
  isIconChanged = false
  isInProgress = false
  error: string | null = null
  errorKeys = {}

  created () {
    this.type = this.currentModalPayload?.type

    if (this.type === FormType.EDIT) {
      this.jid = this.currentModalPayload.jid
      this.group = this.jid ? groupsStore.state.data[this.jid] : undefined
      if (!this.group) return this.close()

      this.name = this.group.displayName
      this.description = this.group.description ?? ''
      this.isPublic = this.group.isPublic ?? false
      this.defaultForAll = this.group.defaultForAll ?? false
      this.readOnly = this.group.readonlyForMembers ?? false
    }
  }

  mounted () {
    DialogEventBus.$on(Events.OPEN_DIALOG, (payload: { instance: string, data: unknown }) => {
      const { instance, data } = payload
      const { name: previousInstance } = this.$options
      uiStore.actions.showModal({ instance, payload: { previousInstance, data } })
    })
  }

  beforeDestroy () {
    DialogEventBus.$off()
  }

  // TODO: remove any, set type for payload
  get currentModalPayload (): any {
    return uiStore.getters.currentModalPayload
  }

  get avatar () {
    return this.jid ? groupsStore.getters.icon(this.jid) : ''
  }

  get isCreateForm () {
    return this.type === FormType.CREATE
  }

  get isEditForm () {
    return this.type === FormType.EDIT
  }

  get changeableFields () {
    return {
      name: this.canChange('display_name'),
      description: this.canChange('description'),
      icons: this.canChange('icons'),
      public: this.canChange('public'),
      defaultForAll: this.canChange('default_for_all'),
      readOnlyForMembers: this.canChange('readonly_for_members'),
    }
  }

  get toggles () {
    const toggles = []

    this.changeableFields.public && toggles.push({
      label: this.$t('modals.GroupForm.commonPublic'),
      value: this.isPublic,
      action: (v: boolean) => (this.isPublic = v),
    })

    this.changeableFields.defaultForAll && toggles.push({
      label: this.$t('modals.GroupForm.defaultForAll'),
      value: this.defaultForAll,
      action: (v: boolean) => (this.defaultForAll = v),
    })

    this.changeableFields.readOnlyForMembers && toggles.push({
      label: this.$t('modals.GroupForm.readOnlyForMembers'),
      value: this.readOnly,
      action: (v: boolean) => (this.readOnly = v),
    })

    return toggles
  }

  get canDelete () {
    return this.group && this.group.canDelete
  }

  get caption () {
    if (this.isCreateForm) return this.$t('modals.GroupForm.create.caption')
    if (this.isEditForm) return this.$t('modals.GroupForm.edit.caption')
  }

  get saveBtnLabel () {
    if (this.isCreateForm) return this.$t('modals.GroupForm.create.saveBtn')
    if (this.isEditForm) return this.$t('modals.GroupForm.edit.saveBtn')
  }

  get saveError () {
    if (this.isCreateForm) return this.$t('modals.GroupForm.errors.create')
    if (this.isEditForm) return this.$t('modals.GroupForm.errors.edit')
  }

  showAllMembersModal () {
    uiStore.actions.showModal({
      instance: 'all-members',
      payload: {
        jid: this.jid,
        previousInstance: 'GroupForm',
        previousPayload: this.currentModalPayload,
      },
    })
  }

  avatarSelected ({ file }: { file: Blob }) {
    this.icon = file
    this.isIconChanged = true
  }

  async commit () {
    this.error = null
    this.errorKeys = {}

    let error: any

    this.isInProgress = true

    error = await this.saveGroup()
    if (error) return (this.error = error)

    error = await this.updateIcon()
    if (error) return (this.error = error)

    this.isInProgress = false

    this.close()
  }

  async saveGroup () {
    const payload: GroupPayload = {
      display_name: this.name,
      description: this.description ? this.description.substr(0, 500) : '',
      public: this.isPublic,
      default_for_all: this.defaultForAll,
      readonly_for_members: this.readOnly,
    }

    try {
      if (this.type === FormType.CREATE) {
        // this conversion should really be happening in api or vuex layer
        payload.members = this.members.map(jid =>
          GroupMembership
            .fromJSON({ jid, status: 'member' })
            .toJSON(),
        )
        const group = await groupsStore.actions.create({ payload })
        this.jid = group.jid
      }

      if (this.type === FormType.EDIT) {
        const groupId = this.jid
        groupId && await groupsStore.actions.edit({ groupId, payload })
      }
    } catch (err) {
      defaultLogger.error(err)
      let { error, details } = err

      if (details) {
        this.errorKeys = details
        Object.keys(details).some(key => (error = details[key]))
      }

      return error || this.saveError
    }
  }

  async updateIcon () {
    if (!this.jid) return

    if (this.icon && this.isIconChanged) {
      try {
        await groupsStore.actions.setIcon({ groupId: this.jid, icon: this.icon })
      } catch (err) {
        return this.$t('modals.GroupForm.errors.setIcon').toString()
      }
    } else if (this.type === FormType.EDIT && this.isIconChanged) {
      try {
        await groupsStore.actions.deleteIcon({ groupId: this.jid })
      } catch (err) {
        return this.$t('modals.GroupForm.errors.deleteIcon').toString()
      }
    }
  }

  async deleteDialog () {
    if (!this.jid || !this.group) return

    uiStore.actions.showModal({
      instance: 'Dialog',
      payload: {
        caption: this.$t('modals.GroupForm.deleteDialog.caption'),
        content: this.$t('modals.GroupForm.deleteDialog.content'),
        icon: groupsStore.getters.icon(this.jid),
        formatData: { name: this.name },
        previousInstance: this.$options.name,
        acceptLabel: this.$t('modals.GroupForm.deleteDialog.acceptLabel'),
        acceptError: this.$t('modals.GroupForm.deleteDialog.acceptError'),
        acceptCallback: async () => {
          this.jid && groupsStore.actions.delete(this.jid)
          this.close()
        },
      },
    })
  }

  canChange (key: string) {
    if (this.isCreateForm) return true
    if (!this.group) return false

    return this.group.changeableFields
      ? this.group.changeableFields.includes(key)
      : this.group.canChangeSettings
  }

  close () {
    uiStore.actions.hideModal()
  }
}
