//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatBytes } from '@/utils'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'FileThumbnail',
  components: {
    close: createSVGComponent({ icon: 'close', size: 12, sizeStorage: 20 }),
    'mark-icon': createSVGComponent({ icon: 'mark_filled', size: 30, sizeStorage: 16, permanentlyActive: true }),

    BaseSpinner,
  },
  props: {
    file: {
      type: File,
      required: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 90,
    },
    state: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      previewImage: null,
      icon: null,
    }
  },
  computed: {
    isImage () {
      const { type } = this.file
      return type.indexOf('image') >= 0
    },
    ext () {
      const temp = this.file.name.split('.').pop()
      return temp.length > 5 ? '' : temp
    },
    size () {
      const { size } = this.file
      return size && formatBytes(size)
    },
  },
  created () {
    if (this.isImage) {
      const reader = new FileReader()
      reader.onload = e => {
        const image = new Image()
        image.onload = () => {
          this.previewImage = image.src
          this.icon = createSVGComponent({ icon: 'pic', size: 15, sizeStorage: 20 })
        }
        image.src = e.target.result
      }
      reader.readAsDataURL(this.file)
      return
    }
    this.icon = createSVGComponent({ icon: 'file', size: 15, sizeStorage: 20 })
  },
}
