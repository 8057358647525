//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from './Modal'
import XModal from './XModals/XModal'
import DefaultModal from './Default'

import { isEscape } from '@/utils/KeyUtils'
import { uiStore } from '@/store'

import FileViewer from './FileViewer/'

import './styles.css'

import UniversalYesNo from './UniversalYesNo'
// import MicInfo from '@/components/Chat/AudioMessages/MicInfo'
// import TextUploaderModal from './TextUploaderModal'
// import Help from './Help'

export default {
  name: 'Modals',
  components: {
    'x-modal': XModal,

    modal: Modal,

    'universal-yes-no': UniversalYesNo,
    // 'text-uploader-modal': TextUploaderModal, // unused
    // 'help-modal': Help,

    DefaultModal,
    FileViewer,
    // MicRequestModal: MicInfo
  },
  computed: {
    currentModal () {
      return uiStore.getters.currentModal
    },
    isExtraModal () {
      return this.extraModals.indexOf(this.currentModal) >= 0
    },
    isDefault () {
      return !this.$options.components[this.currentModal]
    },
  },
  watch: {
    currentModal (value) {
      if (!value) {
        window.removeEventListener('keydown', this.escapeKeyPressed)
        return
      }

      window.addEventListener('keydown', this.escapeKeyPressed) // use keydown for fixing closing modal by closing file dialog with esc
      this.$nextTick(() => {
        const input = this.$el && this.$el.querySelector && this.$el.querySelector('input[type]')
        if (!input || input.getAttribute('data-autofocus-disabled') === 'true') return

        input.focus()
      })
    },
  },
  created () {
    this.extraModals = [
      'add-task-modal',
    ]

    // window.open = () => {
    //   uiStore.actions.showModal({
    //     instance: 'GroupForm',
    //     payload: {
    //       type: 'edit',
    //       jid: 'g-d09b8ad7-630f-4c53-9101-3bf5d5f39c86'
    //     }
    //   })
    // }

    if (window.FEATURES.is_testing) {
      window.openWelcome = () => uiStore.actions.showModal({ instance: 'welcome-modal' })
    }
  },
  methods: {
    escapeKeyPressed (e) {
      isEscape(e) && uiStore.actions.hideModal()
    },
  },
}
