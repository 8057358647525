//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { move } from '@/utils'

import AvatarSelector from '../_CommonUI/AvatarSelector'
import NameInput from '../_CommonUI/NameInput'
import UsersSelector from '@/components/Modals/Default/_CommonUI/UsersSelector/index.vue'
import { Events, DialogEventBus } from '@/components/Modals/XModals/DialogEventBus'
import ContactRow from '../AddContact/ContactRow'
import ContactRowsMixin from '../AddContact/ContactRowsMixin'
import ScrollableArea from '@/components/UI/ScrollableArea'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { teamsStore, uiStore } from '@/store'

export default {
  name: 'TeamCreation',
  components: {
    'avatar-selector': AvatarSelector,
    BaseSpinner,
    'name-input': NameInput,
    'scrollable-area': ScrollableArea,
    UsersSelector,

    ContactRow,
  },
  mixins: [ContactRowsMixin],
  data () {
    return {
      name: '',
      rows: [],
      iconBlob: undefined,

      isInProgress: false,
      error: null,
      errorKeys: {},

      selectedUsers: [],
    }
  },
  computed: {
    numNewTeamMembers () {
      const fromContacts = this.selectedUsers
        ? this.selectedUsers.length
        : 0
      const byPhoneNumber = this.toAdd
        ? this.toAdd.length
        : 0
      return fromContacts + byPhoneNumber
    },
    isMono () {
      return uiStore.getters.currentModalPayload?.mono
    },
    caption () {
      return this.isMono
        ? this.$t('modals.CreationTeam.captionSingleGroupTeam')
        : this.$t('modals.CreationTeam.caption')
    },
    teamNamePlaceholder () {
      return this.isMono
        ? this.$t('modals.CreationTeam.singleGroupTeamNamePlaceholder')
        : this.$t('modals.CreationTeam.teamNamePlaceholder')
    },
  },
  watch: {
    error (value) {
      this.isInProgress = !value
    },
  },
  mounted () {
    DialogEventBus.$on(Events.OPEN_DIALOG, ({ instance, data }) => {
      const { name: previousInstance } = this.$options
      uiStore.actions.showModal({ instance, payload: { previousInstance, data } })
    })
  },
  beforeDestroy () {
    DialogEventBus.$off()
  },
  methods: {
    async commit () {
      this.error = ''
      this.errorKeys = {}

      this.rows.forEach(row => {
        row._error = null
      })

      let error = await this.updateData()
      if (error) return (this.error = error)

      error = await this.updateIcon()
      if (error) return (this.error = error)

      error = await this.addContacts()
      if (error) return (this.error = error)

      move(this.uid)

      setTimeout(this.$parent.close, 1000)
    },
    async updateData () {
      try {
        const team = await teamsStore.actions.createTeam({
          name: this.name,
          isSingleGroup: this.isMono,
        })
        this.uid = team.uid
      } catch (e) {
        let { error, details } = e
        if (details) {
          this.errorKeys = details
          Object.keys(details).some(key => {
            return (error = details[key])
          })
        }
        return error || this.$t('modals.CreationTeam.createTeamError')
      }
    },
    async updateIcon () {
      if (this.iconBlob === undefined || !this.uid) return null

      if (this.iconBlob) {
        try {
          await api.teams.setIcon(this.uid, this.iconBlob)
        } catch (e) {
          return this.$t('modals.CreationTeam.uploadLogoTeamError')
        }
        return null
      } else {
        try {
          await api.teams.deleteIcon(this.uid)
        } catch (e) {
          return this.$t('modals.CreationTeam.deleteLogoTeamError')
        }
      }
      return null
    },
    async addContacts () {
      if (!this.uid) return null

      let resultError = null

      const hasSelectedUsers = this.selectedUsers && this.selectedUsers.length > 0
      if (hasSelectedUsers) {
        const contacts = this.selectedUsers.map(user => ({ user_uid: user.uid }))
        try {
          await api.contacts.addMany(this.uid, contacts)
        } catch (e) {
          console.warn('Error adding contacts to team', e)
          return e.error
        }
      }

      for (const row of this.toAdd) {
        if (row._success) continue

        const data = {
          phone: row.tel,
          given_name: row.name,
          family_name: row.surname,
        }

        try {
          await api.contacts.add(this.uid, data)
          this.$set(row, '_success', true)
        } catch (e) {
          const { details } = e
          if (details) {
            this.$set(row, '_error', details)

            Object.keys(details).some(key => {
              const error = details[key]
              return (resultError = error)
            })
          } else {
            return (resultError = e.error)
          }
          break
        }
      }
      return resultError
    },
  },
}
