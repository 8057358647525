var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-row"},[_c('div',{staticClass:"contact-row__header"},[_c('span',{staticClass:"contact-row__title td-body-medium-m"},[_vm._t("title")],2),_c('TdIconBtn',{attrs:{"type":"additional","size":"m"},on:{"click":function($event){return _vm.$emit('remove')}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('close-icon')]},proxy:true}])})],1),_c('div',[_c('div',{staticClass:"contact-row__info-wrapper"},[_c('div',{staticClass:"contact-row__info",attrs:{"data-cy":"guest-input"}},[_c('TdInput',{staticClass:"col td-guest-phone-number_input",attrs:{"data-cy":"guest-phone-number","type":"tel","placeholder":"+79999999999","error":!_vm.isPhoneValid,"error-message":_vm.$t('modals.AddContact.invalidPhoneFormat'),"maxlength":14,"mask":"+#############"},model:{value:(_vm.data.tel),callback:function ($$v) {_vm.$set(_vm.data, "tel", $$v)},expression:"data.tel"}}),_vm._l((_vm.textInputs),function(props){return _c('TdInput',{key:props.model,staticClass:"col",attrs:{"placeholder":props.placeholder},model:{value:(_vm.data[props.model]),callback:function ($$v) {_vm.$set(_vm.data, props.model, $$v)},expression:"data[props.model]"}})})],2),_c('div',{staticClass:"contact-row__info"},[(_vm.sections)?_c('TdInputSelect',{staticClass:"dropdown-selector col",attrs:{"options":_vm.sections,"option-value":"name","option-label":"name","color":"main-accent","label":_vm.$t('modals.AddContact.toSection'),"popup-content-style":_vm.popupContentStyle,"options-selected-class":"text-bold text-main-accent"},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',{attrs:{"dense":""}},[_c('q-item-section',{staticClass:"text-faded"},[_vm._v(" "+_vm._s(_vm.$t('modals.AddContact.noSection'))+" ")])],1)]},proxy:true},{key:"option",fn:function(ref){
var opt = ref.opt;
var itemEvents = ref.itemEvents;
return [_c('div',_vm._g({staticClass:"contact-row__input-select-option q-pa-sm cursor-pointer"},itemEvents),[_vm._v(" "+_vm._s(opt.name)+" ")])]}},{key:"selected-item",fn:function(ref){
var opt = ref.opt;
return [_c('div',[_vm._v(_vm._s(opt.name))])]}}],null,false,4006640050),model:{value:(_vm.data.section),callback:function ($$v) {_vm.$set(_vm.data, "section", $$v)},expression:"data.section"}}):_vm._e(),_c('TdInputSelect',{staticClass:"dropdown-selector col",attrs:{"options":_vm.availableLevels,"option-value":"name","option-label":"name","color":"main-accent","label":_vm.$t('modals.AddContact.role'),"popup-content-style":_vm.popupContentStyle,"options-selected-class":"text-bold text-main-accent"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var opt = ref.opt;
var itemEvents = ref.itemEvents;
return [_c('div',_vm._g({staticClass:"contact-row__input-select-option q-pa-sm cursor-pointer"},itemEvents),[_vm._v(" "+_vm._s(opt.name)+" ")])]}},{key:"selected-item",fn:function(ref){
var opt = ref.opt;
return [_c('div',[_vm._v(_vm._s(opt.name))])]}}]),model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}},[_vm._v(" > ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }