import { format } from 'quasar'

const { capitalize } = format

export default {
  data () {
    return {
      rows: [],
      showTip: true,
    }
  },
  created () {
    this.counter = 0

    this.previousRowCount = this.startupRows = 1
    for (let i = 0; i < this.startupRows; i++) {
      this.addContactRow()
    }

    const unwatch = this.$watch('rows', () => {
      if (this.rows.length > this.previousRowCount) {
        // this.showTip = false
        this.previousRowCount = null
        unwatch()
        return
      }
      this.previousRowCount = this.rows.length
    })
  },
  computed: {
    toAdd () {
      return this.rows.filter(row => row.tel)
    },
  },
  methods: {
    handleRowRemove (row, index) {
      this.rows.splice(index, 1)
    },
    handleRowStartPhoneType (row, index) {
      if (index === this.rows.length - 1) {
        this.addContactRow()
      }
    },
    addContactRow () {
      this.rows.push({
        _id: this.counter,
        _error: null,
        _success: false,
        tel: '',
        name: '',
        surname: '',
        patronymic: '',
        status: { id: 'member', name: capitalize(this.$t('modals.EditContact.member')) },
        section: null, // this.sections ? this.sections[0] : null
      })
      this.counter++
    },
  },
}
