//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import InstructionItem from '@/components/UI/InstructionItem'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import ScrollableArea from '@/components/UI/ScrollableArea'
import { networkLogger } from '@/loggers'
import { uiStore } from '@/store'
import { format } from 'quasar'

const { capitalize } = format

export default {
  name: 'CalendarIntegration',
  components: {
    'scrollable-area': ScrollableArea,
    'instruction-item': InstructionItem,
    BaseSpinner,
    'copy-icon': createSVGComponent({ icon: 'copy', size: 18, sizeStorage: 20 }),
  },
  data () {
    return {
      isFetching: true,
      isClicked: false,
      icsURL: '',
      yandex: true,
      google: false,
      icalendar: false,
    }
  },
  computed: {
    isTesting () {
      const { is_testing: isTesting } = window.FEATURES
      return isTesting
    },
    closeText () {
      return capitalize(this.$t('glossary.close'))
    },
  },
  created () {
    this.fetch()
  },
  beforeDestroy () {
    this.fetchTimeout && clearTimeout(this.fetchTimeout)
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
    async fetch () {
      try {
        const response = await api.calendarIntegration.getICS()
        this.icsURL = response.ics_url
        this.isFetching = false
      } catch (e) {
        networkLogger.warn(e)

        this.fetchTimeout(this.fetch, 1200)
      }
    },
    onTabClick (value) {
      switch (value) {
        case 'yandex': {
          this.yandex = true
          this.google = false
          this.icalendar = false
          return
        }
        case 'google': {
          this.yandex = false
          this.google = true
          this.icalendar = false
          return
        }
        case 'icalendar': {
          this.yandex = false
          this.google = false
          this.icalendar = true
        }
      }
    },
    copyToClipboard () {
      window.goal('userControls', {
        userControls: 'Скопировать ссылку в «Интеграциях с Календарями»',
      })

      if (this.icsURL) {
        navigator.clipboard.writeText(this.icsURL.toString().trim()).then(() => {
          this.isClicked = true
          const v = this
          setTimeout(function () {
            v.isClicked = false
            // console.log('this.isClicked → ' + this.isClicked)
          }, 2000)
          // console.log('this.isClicked → ' + this.isClicked)
          if (this.isTesting) console.log('Скопировалось → ' + this.icsURL)
        })
          .catch(err => {
            if (this.isTesting) console.log('Не скопировалось → ', err)
          })
      }
    },
  },
}
