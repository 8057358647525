//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import difference from 'lodash.difference'

import api from '@/api/v3'
import AvatarSelector from '../_CommonUI/AvatarSelector'
import NameInput from '../_CommonUI/NameInput'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import ContactsPicker from '@/components/UI/ContactsPicker'
import { Events, DialogEventBus } from '@/components/Modals/XModals/DialogEventBus'

import { format } from 'quasar'

import { mapGetters } from 'vuex'
import {
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
} from '@/store'

const { capitalize } = format

export default {
  name: 'TeamSettingsModal',
  components: {
    'contacts-picker': ContactsPicker,
    'avatar-selector': AvatarSelector,
    'name-input': NameInput,
    BaseSpinner,
    IconQuestionCircle: () => import('@/components/UI/icons/IconQuestionCircle'),
  },
  data () {
    return {
      name: '',
      owner: null,
      addAsAdmins: [],
      deadlineTime: '',
      editTime: 0,

      isInProgress: false,
      error: null,

      settingsSection: 'general',

      useTaskComplexity: false,
      useTaskImportance: false,
      useTaskSpentTime: false,
      useTaskTitle: false,
      useTaskUrgency: false,
    }
  },
  watch: {
    error (value) {
      this.isInProgress = !value
    },
  },
  created () {
    this.iconBlob = undefined

    this.teamId = uiStore.getters.currentModalPayload

    const result = teamsStore.state.data[this.teamId] || {}
    const {
      name,
      defaultTaskDeadline,
      maxMessageUpdateAge,
      useTaskComplexity,
      useTaskImportance,
      useTaskSpentTime,
      useTaskTitle,
      useTaskUrgency,
      // hideArchivedUsers
    } = this.team = Object.assign({}, result)

    this.name = name || this.name
    this.deadlineTime = defaultTaskDeadline
    this.editTime = maxMessageUpdateAge
    this.owner = contactsStore.getters.teamAdmins.filter(item => item.status === 'owner')[0]
    this.addAsAdmins = contactsStore.getters.teamAdmins.filter(item => item.status === 'admin')
    this.team.hideArchivedUsers && (this.addAsAdmins = this.addAsAdmins.filter(contact => !contact.isArchive))
    this.adminsBeforeEdit = this.addAsAdmins.slice(0)

    this.useTaskComplexity = !!useTaskComplexity
    this.useTaskImportance = !!useTaskImportance
    this.useTaskSpentTime = !!useTaskSpentTime
    this.useTaskTitle = !!useTaskTitle
    this.useTaskUrgency = !!useTaskUrgency
  },
  mounted () {
    DialogEventBus.$on(Events.OPEN_DIALOG, ({ instance, data }) => {
      const { name: previousInstance } = this.$options
      uiStore.actions.showModal({ instance, payload: { previousInstance, data } })
    })
  },
  beforeDestroy () {
    DialogEventBus.$off()
  },
  computed: {
    ...mapGetters([
      'hasExtraStatuses',
    ]),
    showTasksRelated () {
      return uiStore.getters.showTasksRelated
    },
    hasExtraStatuses () {
      return tasksStore.getters.hasExtraStatuses
    },
    tabs () {
      const general = {
        name: 'general',
        title: this.$t('modals.TeamSettings.tabs.general'),
      }
      const tasks = {
        name: 'tasks',
        title: this.$t('modals.TeamSettings.tabs.tasks'),
      }

      return [general, tasks]
    },
    icon () {
      return teamsStore.getters.teamIconURL(this.team.uid)
    },
    isSingleGroupTeam () {
      return teamsStore.getters.isSingleGroupTeam()
    },
    editMsgHintCaption () {
      const restricted = capitalize(this.$t('modals.TeamSettings.editMsgSetting.hint.restricted').toString())
      const unrestricted = capitalize(this.$t('modals.TeamSettings.editMsgSetting.hint.unrestricted').toString())
      return { restricted, unrestricted }
    },
    caption () {
      return capitalize(this.isSingleGroupTeam
        ? this.$t('modals.TeamSettings.captionSingleGroupTeam').toString()
        : this.$t('modals.TeamSettings.caption')).toString()
    },
    teamNameInputCaption () {
      return this.isSingleGroupTeam
        ? this.$t('modals.TeamSettings.singleGroupTeamName').toString()
        : this.$t('modals.TeamSettings.teamName').toString()
    },
    deleteLabel () {
      return this.isSingleGroupTeam
        ? this.$t('modals.TeamSettings.deleteCommandSingleGroup').toString()
        : this.$t('modals.TeamSettings.deleteCommand').toString()
    },
    pickContactsFrom () {
      return contactsStore.getters.contactList
        .filter(contact => !contact.isArchive)
        .filter(contact => contact.status === 'member')
    },
    isRequiredFieldsFilled () {
      return Boolean(this.name && this.deadlineTime)
    },
  },
  methods: {
    remove () {
      const { name: previousInstance } = this.$options

      const dialogOptions = {
        caption: this.$t('modals.TeamSettings.deleteDlg.caption'),
        icon: this.icon,
        content: this.$t('modals.TeamSettings.deleteDlg.content'),
        formatData: { name: this.team.name },
        acceptCallback: async () => {
          await api.teams.delete(this.teamId)
          this.close()
        },
        acceptLabel: this.$t('modals.TeamSettings.deleteDlg.acceptLbl'),
        acceptError: this.$t('modals.TeamSettings.deleteDlg.acceptErr'),
      }

      uiStore.actions.showModal({
        instance: 'Dialog',
        payload: Object.assign(dialogOptions, { previousInstance }),
      })
    },
    close () {
      uiStore.actions.hideModal()
    },
    async commit () {
      this.error = ''

      let error = await this.updateAdmins()
      if (error) return (this.error = error)

      error = await this.updateIcon()
      if (error) return (this.error = error)

      error = await this.updateData()
      if (error) return (this.error = error)

      this.close()
    },
    async updateIcon () {
      if (this.iconBlob === undefined) return null

      if (this.iconBlob) {
        try {
          await api.teams.setIcon(this.teamId, this.iconBlob)
        } catch (e) {
          return this.$t('modals.TeamSettings.uploadLogoTeamErr')
        }
        return null
      }

      try {
        await api.teams.deleteIcon(this.teamId)
      } catch (e) {
        return this.$t('modals.TeamSettings.deleteLogoTeamErr')
      }
      return null
    },
    async updateData () {
      try {
        await api.teams.edit(this.teamId, {
          name: this.name,
          default_task_deadline: this.deadlineTime,
          max_message_update_age: this.editTime,
          use_task_complexity: this.useTaskComplexity,
          use_task_importance: this.useTaskImportance,
          use_task_spent_time: this.useTaskSpentTime,
          use_task_title: this.useTaskTitle,
          use_task_urgency: this.useTaskUrgency,
        })
        if (
          this.useTaskComplexity !== !!this.team.useTaskComplexity ||
          this.useTaskImportance !== !!this.team.useTaskImportance ||
          this.useTaskSpentTime !== !!this.team.useTaskSpentTime ||
          this.useTaskUrgency !== !!this.team.useTaskUrgency
        ) window.location.reload()
      } catch (e) {
        return this.$t('modals.TeamSettings.createTeamErr')
      }
      return null
    },
    async updateAdmins () {
      const toAdmins = difference(this.addAsAdmins, this.adminsBeforeEdit)
      const toMembers = difference(this.adminsBeforeEdit, this.addAsAdmins)

      for (const contact of [...toAdmins, ...toMembers]) {
        try {
          const { jid } = contact

          const status = toAdmins.indexOf(contact) < 0 ? 'member' : 'admin'
          await api.contacts.edit(jid, { status }, this.teamId)
        } catch (e) {
          return this.$t('modals.TeamSettings.changeContactStatusErr', { name: this.contact.displayName })
        }
      }
      return null
    },
    avatarSelected ({ file }) {
      this.iconBlob = file
    },
  },
}
