//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { throttle } from 'quasar'

export default {
  name: 'ColoredSearchInput',
  components: {
    close: createSVGComponent({ icon: 'close', size: 16 }),
    search: createSVGComponent({ icon: 'search', size: 20 }),
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#CCCFD5',
    },
    placeholderColor: {
      type: String,
      default: '#999FAB',
    },
    textColor: {
      type: String,
      default: '#5e616b',
    },
    delay: {
      type: Number,
      default: 0,
    },
    focusTextColor: {
      type: String,
      default: '',
    },
    focusPlaceholderColor: {
      type: String,
      default: '',
    },
    focusBackgroundColor: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    isInverseClose: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      filterInput: '',
      focused: false,
    }
  },

  computed: {
    iconFill () {
      if (this.iconColor) return this.iconColor

      return this.focused
        ? (this.focusTextColor || this.textColor)
        : this.placeholderColor
    },
    clearIconClass () {
      const classes = {
        invisible: !this.value,
      }

      this.isInverseClose
        ? classes['clear-icon--inverse'] = true
        : classes['clear-icon--hover'] = true

      return classes
    },
    computedPlaceholderColor () {
      return (this.focused && this.focusPlaceholderColor)
        ? this.focusPlaceholderColor
        : this.placeholderColor
    },
  },
  created () {
    this.emitInputValue = value => {
      this.$emit('input', (value || '').trim())
    }

    this.emitInput = this.delay ? throttle(this.emitInputValue, this.delay) : this.emitInputValue
  },
  beforeDestroy () {
    this.emitInput.cancel && this.emitInput.cancel()
  },

  methods: {
    clearInput () {
      this.$emit('input', '')
      this.blur()
    },
    blur () {
      const { input } = this.$refs
      input && input.blur()
    },
    focus () {
      const { input } = this.$refs
      input && input.focus()
    },
    toggleFocus (e) {
      this.$emit('toggleFocus', this.focused = e.type === 'focus')
    },
    /** Using from parent component */
    getInputElement () {
      return this.$refs.input
    },
  },
}
