//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './styles.scss'

import api from '@/api/v3'
import {
  sectionsStore,
  contactsStore,
  teamsStore,
  uiStore,
} from '@/store'
import { transformEntityName } from '@/utils'

import NameInput from '../_CommonUI/NameInput'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import DropdownSelector from '@/components/UI/DropdownSelector'

import { format } from 'quasar'

const { capitalize } = format

export default {
  name: 'EditContact',
  components: {
    'name-input': NameInput,
    BaseSpinner,
    'dropdown-selector': DropdownSelector,
  },
  data () {
    return {
      familyName: '',
      givenName: '',
      patronymic: '',
      role: '',
      accessStatus: null,
      section: null,
      selectedSections: [],
      isArchive: false,

      isInProgress: false,
      error: null,
      errorKeys: {},
    }
  },
  computed: {
    textInputs () {
      const nameTypes = {
        family_name: this.familyNameInput,
        given_name: this.firstNameInput,
        patronymic: this.patronymicNameInput,
      }

      return teamsStore.getters.currentTeamUserFields
        .filter(field => this.canChange(field))
        .map(field => nameTypes[field])
    },
    familyNameInput () {
      return {
        model: this.familyName,
        error: this.errorKeys.family_name,
        placeholder: this.$t('modals.Contact.lastName'),
      }
    },
    firstNameInput () {
      return {
        model: this.givenName,
        error: this.errorKeys.given_name,
        placeholder: this.$t('modals.Contact.firstName'),
      }
    },
    patronymicNameInput () {
      return {
        model: this.patronymic,
        error: this.errorKeys.patronymic,
        placeholder: this.$t('modals.Contact.patronymicName'),
      }
    },
    data () {
      const payload = uiStore.getters.currentModalPayload
      if (typeof payload === 'string') {
        return contactsStore.getters.contact(payload)
      }
      return payload || {}
    },
    sections () {
      const preparedSections = Object.values(sectionsStore.state.direct).map(sectionObj => {
        return {
          uid: sectionObj.uid,
          name: sectionObj.name,
        }
      })
      return preparedSections
    },
    accessLevels () {
      return [
        { id: 'guest', name: capitalize(this._mt('guest')) },
        { id: 'member', name: capitalize(this._mt('member')) },
        { id: 'admin', name: capitalize(this._mt('admin')) },
        { id: 'owner', name: capitalize(this._mt('owner')) },
      ]
    },
    availableLevels () {
      return this.accessLevels.filter(
        item => this.changeableStatuses.indexOf(item.id) !== -1,
      )
    },
    changeableStatuses () {
      return teamsStore.getters.currentTeam.changeableStatuses
    },
  },
  created () {
    this._mt = key => this.$t(`modals.${this.$options.name}.${key}`)

    if (this.data) {
      const { givenName, familyName, patronymic, role, status, displayName, isArchive } = this.data
      this.name = displayName

      this.givenName = this.givenName || givenName
      this.familyName = this.familyName || familyName
      this.patronymic = this.patronymic || patronymic
      this.isArchive = this.isArchive || isArchive
      this.role = this.role || role
      this.selectedSections = contactsStore.getters.contact(this.data.jid).sections.map(uid => {
        return {
          uid,
          name: sectionsStore.getters.sectionNameByUid(uid),
        }
      })
      this.accessStatus = this.accessLevels.filter(item => item.id === status)[0]
    }
  },
  methods: {
    canChange (field) {
      return this.data.changeableFields.indexOf(field) !== -1
    },
    close () {
      uiStore.actions.hideModal()
    },
    async commit () {
      this.error = ''
      this.errorKeys = {}

      const error = await this.updateData()
      if (error) return (this.error = error)

      this.close()
    },
    async updateData () {
      const data = {}
      data.given_name = this.givenName
      data.family_name = this.familyName
      data.patronymic = this.patronymic
      data.role = this.role
      data.status = this.accessStatus.id
      data.sections = this.selectedSections
        ? this.selectedSections.map(sectionObj => sectionObj.uid)
        : []

      try {
        await api.contacts.edit(this.data.jid, data)
      } catch (e) {
        let { error, details } = e
        if (details) {
          this.errorKeys = details
          Object.keys(details).some(key => {
            return (error = details[key])
          })
        }
        return error || this._mt('fallbackError')
      }
    },
    transformEntityName,
  },
}
