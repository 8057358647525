//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { getAspectRatioSize } from '@/utils/Common'

export default {
  name: 'VideoView',
  components: {
    BaseSpinner,
  },
  props: ['file', 'fullscreen'],
  data () {
    return {
      width: 0,
      height: 0,
      videoWidth: 0,
      videoHeight: 0,
      maxAllowedWidth: 0,
      maxAllowedHeight: 0,
    }
  },
  computed: {
    isReady () {
      return this.width && this.height
    },
  },
  watch: {
    fullscreen () {
      this.calcMaxSizes()
    },
  },
  mounted () {
    this.calcMaxSizes()
  },
  methods: {
    handleLoadedMetadata (event) {
      const { target } = event
      if (!target) return

      const { videoWidth, videoHeight } = target

      this.videoWidth = videoWidth
      this.videoHeight = videoHeight

      const { width, height } = getAspectRatioSize({
        maxWidth: this.maxAllowedWidth,
        maxHeight: Math.min(this.maxAllowedHeight / 1.25, this.maxAllowedHeight - 180),
        width: this.videoWidth,
        height: this.videoHeight,
      })

      this.width = width
      this.height = height
    },
    handleViewClick (event) {
      const { currentTarget, target } = event
      if (currentTarget !== target) return

      const closeFunction = this.$parent.close
      closeFunction && closeFunction()
    },
    calcMaxSizes () {
      const { offsetWidth, offsetHeight } = this.$el

      this.maxAllowedWidth = offsetWidth
      this.maxAllowedHeight = offsetHeight
    },
  },
}
