//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollableArea from '@/components/UI/ScrollableArea'
import { createAttachmentContainer } from '@/components/Chat/Instance/DOM/Components/Attachments'
import { uiStore } from '@/store'
import { flushElement, measureHeight } from '@/utils/DOM'

export default {
  name: 'RedirectMessagesContainer',
  components: {
    ScrollableArea,
  },
  data () {
    return {
      height: 0,
    }
  },
  created () {
    this.maxHeight = 150

    const payload = uiStore.getters.currentModalPayload

    this.messages = Array.isArray(payload) ? payload : [payload]
  },
  mounted () {
    const { innerContainer, scrollableArea } = this.$refs
    if (!innerContainer || !scrollableArea) return

    flushElement(innerContainer)

    this.messages = this.messages.filter(m => !!m)

    const attachmentContainerElement = createAttachmentContainer(this.messages, innerContainer)
    innerContainer.appendChild(attachmentContainerElement)

    this.height = Math.min(measureHeight(attachmentContainerElement), 150)

    this.$emit('input', this.messages)
  },
}
