//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TagList',
  props: {
    value: {
      type: Array,
      required: true,
    },
    static: {
      type: Array,
      default: () => [],
    },
    maxDisplay: {
      type: Number,
      default: -1,
    },
  },
  data () {
    return { max: this.maxDisplay }
  },
  computed: {
    cupped () {
      const { max } = this
      const value = this.value || []
      if (max < 0) return value

      return value.slice(0, max)
    },
    left () {
      const { max } = this
      if (max < 0) return []

      const value = this.value || []
      return value.slice(max)
    },
  },
  watch: {

  },
  mounted () {
    this.previousTagsCount = this.value.length
  },
  beforeUpdate () {
    const currentTagsCount = this.value.length

    if (currentTagsCount > this.previousTagsCount) this.max = -1

    this.previousTagsCount = currentTagsCount
  },
  methods: {
    isStatic (contact) {
      return this.static.indexOf(contact) >= 0
    },
    toggleLeft () {
      this.max = -1
    },
    clickItem (exclude) {
      this.$emit('click', exclude)

      const emitValue = this.value.filter(item => item !== exclude)
      this.$emit('input', emitValue)

      if (emitValue.length <= this.maxDisplay) this.max = this.maxDisplay
    },
  },
}
