//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { defaultLogger } from '@/loggers'
import { highlightMatches, preventHtmlTags, parseEmoji } from '@/utils'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { groupsStore, publicGroupsStore, teamsStore, uiStore } from '@/store'
import { getRandomIconPlaceholder } from '@/api/v3/DataAdapter'

export default {
  name: 'GroupInstanceRow',
  components: {
    BaseSpinner,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    icon () {
      return publicGroupsStore.getters.icon(this.instance.jid) || getRandomIconPlaceholder('group')
    },
    name () {
      return parseEmoji(highlightMatches(preventHtmlTags(this.instance.displayName), this.filter))
    },
    membersCount () {
      return this.instance.numMembers
    },
    isIn () {
      const { getters } = this.$store

      const chat = getters.chat(this.instance.jid)
      if (chat && chat.isForSneakpeek) return false

      return groupsStore.getters.isInGroup(this.instance.jid, getters.getUserId)
    },
  },
  methods: {
    handleNameClick () {
      const { getters } = this.$store
      const chat = getters.chat(this.instance.jid)

      if (chat && publicGroupsStore.getters.wasJoined(chat.chatId)) {
        this.$router.push({
          name: 'Chat',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
            jid: chat.chatId,
          },
        })
      } else {
        publicGroupsStore.actions.sneakpeekGroup(this.instance.jid)
      }

      uiStore.actions.hideModal()
    },
    async join () {
      this.loading = true

      try {
        if (this.isIn) {
          const { getters } = this.$store
          await api.groups.deleteMember(this.instance.jid, getters.getUserId)
        } else {
          await publicGroupsStore.actions.join(this.instance.jid)
        }
      } catch (e) {
        defaultLogger.warn(e)
      }

      this.loading = false
    },
  },
}
