//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { differenceBy, sortBy } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { transformEntityName } from '@/utils'
import * as actionTypes from '@/store/actionTypes'
import {
  groupsStore,
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
} from '@/store'
import api from '@/api/v3'
import BaseContactAvatar from '@/components/UI/BaseEntityAvatar.vue'
import EntityCard from '../EntitySelector/EntityCard'
import Fuse from 'fuse.js'
import PopupMenuWrapper from '@/components/UI/Wrappers/PopupMenuWrapper'
import ScrollableArea from '@/components/UI/ScrollableArea'
import SearchColoredInput from '@/components/UI/SearchColoredInput'
import { showLeaveGroupDialog } from '@/components/Modals/Default/Dialog/Presets'
import { roundWest } from '@quasar/extras/material-icons-round'
import { throttle } from 'quasar'

// нет слов, один эмоции
const searchFactory = () => throttle((text, users) => {
  const options = {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    keys: [
      'name',
    ],
  }
  const fuse = new Fuse(users, options)
  return fuse.search(text).map(v => v.item)
}, 100)

const search = searchFactory()
const searchAdd = searchFactory()

export default {
  name: 'AllMembers',

  components: {
    BaseContactAvatar,
    EntityCard,
    GroupIcon: () => import('@/components/UI/GroupIcon'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis'),
    PopupMenuWrapper,
    ScrollableArea,
    SearchColoredInput,
  },

  data () {
    return {
      search: '',

      maxHeight: 0,

      edit: false,
      selectedMembers: [],
      inProgress: false,
      error: null,
      errorLoadingMembers: false,
    }
  },

  watch: {
    edit () {
      this.selectedMembers = []
      this.inProgress = false
      this.error = null
      this.search = ''
    },
  },

  created () {
    this.iconBack = roundWest
    this.resizeListener = throttle(() => {
      const defaultHeight = 500
      this.maxHeight = Math.max(window.innerHeight - 200, defaultHeight)
      this.maxHeight = this.maxHeight + 'px'
    }, 100)
    window.addEventListener('resize', this.resizeListener)

    this.resizeListener()
  },

  mounted () {
    if (this.currentModalPayload.edit) {
      this.edit = true
    }

    this.loadMembers()
  },

  beforeDestroy () {
    this.resizeListener && window.removeEventListener('resize', this.resizeListener)
  },

  computed: {
    ...mapGetters([
      'chatType',
      'entity',
      'getUserId',
      'profile',
    ]),
    currentModalPayload () {
      return uiStore.getters.currentModalPayload
    },
    chat () {
      return this.entity(this.currentModalPayload.jid)
    },
    isTask () {
      return this.chatType(this.chat.jid) === 'task'
    },
    isMeeting () {
      return this.chatType(this.chat.jid) === 'meeting'
    },
    hideArchivedUsers () {
      return teamsStore.getters.currentTeam.hideArchivedUsers ?? false
    },
    members () {
      // return this.isTask ? this.chat.observers : this.chat.members // /////////
      if (this.isTask) {
        const { assignee, owner, observers } = this.chat

        const members = [{ jid: owner, status: owner === assignee ? 'owner+assignee' : 'owner' }]
        if (assignee && assignee !== owner && observers.indexOf(assignee) === -1) {
          members.push({ jid: assignee, status: 'assignee' })
        }

        if (this.hideArchivedUsers) {
          return [...members, ...observers.map(jid => ({ jid, status: 'member' }))].filter(this.hideArchive)
        }

        return [...members, ...observers.map(jid => ({ jid, status: 'member' }))]
      } else {
        if (this.hideArchivedUsers) {
          return this.chat.members?.filter(this.hideArchive) || []
        }
        return this.chat.members || []
      }
    },
    memberList () {
      if (this.search.trim() === '') {
        const sortFn = (m1, m2) => {
          const m1arch = this.isArchive(m1)
          const m2arch = this.isArchive(m2)
          if (m1arch !== m2arch) return m1arch - m2arch
          const m1name = this.displayName(m1.jid).toLowerCase()
          const m2name = this.displayName(m2.jid).toLowerCase()
          if (m1name < m2name) return -1
          if (m1name > m2name) return 1
          return 0
        }
        return this.members.sort(sortFn)
      }

      const mappedForSearch = this.members.map(member => ({
        jid: member.jid,
        status: member.status,
        name: this.displayName(member.jid),
        can_remove: member.can_remove,
      }))
      return search(
        this.search,
        mappedForSearch,
      )
    },
    membersToAddFull () {
      return differenceBy(
        contactsStore.getters.contactList
          .filter(contact => this.isTask ? contact.canCreateTask : contact.canAddToGroup)
          .map(c => ({ jid: c.jid, name: this.displayName(c.jid) })),
        this.members,
        'jid',
      )
    },
    membersToAdd () {
      return this.search.trim() === ''
        ? sortBy(this.membersToAddFull, m => this.displayName(m.jid).toLowerCase())
        : searchAdd(this.search, this.membersToAddFull)
    },

    chatTitle () {
      return this.isTask
        ? `${this.$t('modals.allObservers.taskTitle')} #${this.chat.num}`
        : transformEntityName(this.chat.displayName)
    },
    memberCount () {
      return this.chat?.numMembers || this.members.length
    },
    notFound () {
      return this.search.length > 0 && (this.edit ? this.membersToAdd : this.memberList).length === 0
    },
    noMembers () {
      return this.members?.length === 0 && this.chat?.numMembers === 0
    },
    memberCountText () {
      const count = this.memberCount
      return `${count} ${this.$tc('modals.allObservers.user', count)}`
    },
    canAddMembers () {
      if (this.isSingleGroupTeam()) return this.profile.canAddToGroup
      return this.isTask
        ? this.chat.changeableFields.indexOf('observers') !== -1
        : this.isMeeting ? false : this.chat.canRemoveMember
    },
  },

  methods: {
    ...mapActions([
      actionTypes.UPDATE_CHAT,
    ]),
    isSingleGroupTeam () {
      return teamsStore.getters.isSingleGroupTeam()
    },
    contact (jid) {
      return contactsStore.getters.contact(jid)
    },
    contactIcon (jid) {
      return contactsStore.getters.contactIcon(jid)
    },
    contactIsAfk (jid) {
      return contactsStore.getters.contactIsAfk(jid)
    },
    contactOnlineType (jid) {
      return contactsStore.getters.contactOnlineType(jid)
    },
    hideArchive (member) {
      return (member?.jid === this.chat.owner || member?.jid === this.chat.assignee) || !this.isArchive(member)
    },
    async loadMembers () {
      if (!this.isTask && !this.chat.members) {
        this.errorLoadingMembers = false
        this.error = null
        this.inProgress = true
        try {
          // await new Promise(resolve => setTimeout(resolve, 1000))
          // if (Math.random() > 0.2) throw new Error()
          await groupsStore.actions.loadMembers(this.currentModalPayload.jid)
        } catch (e) {
          this.errorLoadingMembers = true
          this.error = this.$t('modals.allObservers.errorLoadingMembers')
        } finally {
          this.inProgress = false
        }
      }
    },
    async doWithLoading (fn) {
      this.error = null
      this.inProgress = true

      let result
      try {
        result = await fn()
      } catch (e) {
        this.error = e.error || this.$t('modals.allObservers.stdErr')
      }

      this.inProgress = false
      return result
    },
    // TODO отрефакторить нафиг.
    menuOptions (member) {
      return () => {
        // TODO dropdown position
        const options = []

        if (this.isTask) {
          this.chat.changeableFields.includes('assignee') &&
          this.chat.assignee !== this.getUserId &&
          options.push({
            key: 'assign',
            name: member.status === 'member' || member.status === 'owner'
              ? this.$t('modals.allObservers.setAssignee')
              : this.$t('modals.allObservers.unsetAssignee'),
            action: () => this.doWithLoading(async () => {
              const observers = [...this.chat.observers]
              let assignee = this.chat.assignee
              const owner = this.chat.owner

              if (member.status === 'member' || member.status === 'owner') {
                if (assignee && assignee !== owner && observers.indexOf(assignee) === -1) {
                  observers.push(assignee)
                }

                assignee = member.jid
              } else {
                if (assignee !== owner && observers.indexOf(assignee) === -1) {
                  observers.push(assignee)
                }

                assignee = null
              }

              await tasksStore.actions.edit({
                jid: this.chat.jid,
                task: { observers, assignee },
              })
            }),
          })

          const canRemove = member.jid !== this.chat.owner && (member.jid === this.chat.assignee
            ? this.chat.changeableFields.indexOf('assignee') !== -1
            : this.chat.changeableFields.indexOf('observers') !== -1)

          canRemove && options.push({
            key: 'remove-task-member',
            name: this.$t('modals.allObservers.removeTaskMember.name'),
            action: () => {
              const taskJid = this.chat.jid
              const observers = [...this.chat.observers].filter(m => m !== member.jid)

              let assignee = this.chat.assignee
              if (member.jid === assignee) {
                assignee = null
              }

              uiStore.actions.showModal({
                instance: 'universal-yes-no',
                payload: {
                  title: this.$t('modals.allObservers.removeTaskMember.title'),
                  text: `${this.$t('modals.allObservers.removeTaskMember.text.part1')} <b>${this.displayName(member.jid)}</b> ${this.$t('modals.allObservers.removeTaskMember.text.part2')} <b>${transformEntityName(this.chat.title)}</b>?`,
                  yesText: this.$t('modals.allObservers.removeTaskMember.yesText'),
                  previousInstance: 'all-members',
                  previousPayload: this.currentModalPayload,
                  yes: async () => {
                    await tasksStore.actions.edit({
                      jid: taskJid,
                      task: { observers, assignee },
                    })
                  },
                },
              })
            },
          })
        } else if (!this.isMeeting) {
          (!this.isArchive(member) && this.chat.canChangeMemberStatus) && options.push({
            key: 'make-admin',
            name: member.status === 'member' ? this.$t('modals.allObservers.setAdmin') : this.$t('modals.allObservers.unsetAdmin'),
            action: () => this.doWithLoading(async () => {
              const groupId = this.chat.jid
              const memberId = member.jid
              const status = member.status === 'admin' ? 'member' : 'admin'
              await groupsStore.actions.changeMemberStatus({ groupId, memberId, status })
            }),
          })

          const thatChat = this.chat
          // если can_remove нет - значит пользователь только добавлен и может быть удален этим же человеком
          const canRemove = member.can_remove ?? true

          if (canRemove) {
            const action = this.isYou(member.jid)
              ? this.leaveGroup
              : () => uiStore.actions.showModal({
                instance: 'universal-yes-no',
                payload: {
                  title: this.$t('modals.allObservers.removeMember.title'),
                  text: `${this.$t('modals.allObservers.removeMember.text.part1')} <b>${this.displayName(member.jid)}</b> ${this.$t('modals.allObservers.removeMember.text.part2')} <b>${transformEntityName(this.chat.displayName)}</b>?`,
                  yesText: this.$t('modals.allObservers.removeMember.yesText'),
                  previousInstance: 'all-members',
                  previousPayload: this.currentModalPayload,
                  yes: async () => {
                    await groupsStore.actions.deleteMember({ groupId: thatChat.jid, memberId: member.jid })
                    this.isInProgress = false
                  },
                },
              })

            options.push({
              key: 'remove-member',
              name: this.isYou(member.jid) ? this.$t('common.leaveGroup') : this.$t('modals.allObservers.removeMember.name'),
              action,
            })
          }
        }

        // options.length && options.push({ divider: true })
        options.push({
          key: 'view-profile',
          name: this.$t('modals.allObservers.showProfile'),
          action: () => {
            uiStore.actions.switchRightBarInstance({
              instance: 'contact-profile',
              payload: member.jid,
            })
            this.close()
          },
        })

        return options
      }
    },
    toggleEdit () {
      this.edit = !this.edit
    },
    addSingleGroupTeamMembers () {
      uiStore.actions.showModal({ instance: 'AddContact' })
    },
    onMemberClick (member) {
      if (this.edit) {
        const index = this.selectedMembers.indexOf(member.jid)

        const remove = index >= 0
        if (remove) {
          this.selectedMembers.splice(index, 1)
          return
        }
        this.selectedMembers.push(member.jid)

        this.$nextTick(() => {
          const { scrollableArea } = this.$refs
          if (!scrollableArea || typeof scrollableArea.scrollDown !== 'function') return

          scrollableArea.scrollDown()
        })
      }
    },
    async addSelectedMembers () {
      if (this.selectedMembers.length === 0) {
        return
      }

      await this.doWithLoading(async () => {
        if (this.isTask) {
          const observers = [...this.chat.observers, ...this.selectedMembers]

          await tasksStore.actions.edit({
            jid: this.chat.jid,
            task: { observers },
          })
        } else {
          const { jid: groupId, members, displayName } = this.chat

          const toAdd = this.selectedMembers
            .map(jid => ({ jid, status: 'member' }))
            .concat(
              members.map(({ jid, status }) => ({ jid, status })),
            )

          let groupData = null
          groupData = await api.groups.edit(groupId, {
            display_name: displayName,
            members: toAdd,
          })

          if (groupData) {
            const { chat, group } = groupData
            chat && this.UPDATE_CHAT(chat)
            group && groupsStore.actions.update(group)
          }
        }
      })

      if (!this.error) {
        this.selectedMembers = []
        // this.edit = false
        this.close()
      }
    },
    close () {
      uiStore.actions.hideModal()
    },
    displayName (jid) {
      return transformEntityName(contactsStore.getters.contactDisplayName(jid))
    },
    isArchive (member) {
      return contactsStore.getters.contact(member.jid).isArchive ?? false
    },
    isYou (jid) {
      return jid === this.getUserId
    },
    leaveGroup () {
      showLeaveGroupDialog(this.chat.jid)
    },
  },
}
