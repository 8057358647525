













import { Vue, Component, Emit } from 'vue-property-decorator'

@Component
export default class LoadErrorSection extends Vue {
  @Emit()
  reload () { }
}
