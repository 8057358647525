//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { uiStore, sectionsStore } from '@/store'
import api from '@/api/v3'
import ContactsPicker from '@/components/UI/ContactsPicker'
import SortableList from './SortableList'
import { format } from 'quasar'

const { capitalize } = format

export default {
  name: 'SectionModal',
  components: {
    'arrow-icon': createSVGComponent({ icon: 'arr_mini', size: 14 }),
    ContactsPicker,
    BaseSpinner: () => import('@/components/UI/BaseSpinner.vue'),
    SortableList,
  },
  data () {
    return {
      section: null,

      name: '',
      contacts: [],
      order: 0,

      isInProgress: false,
      error: null,
      errorKeys: {},

      maxLength: 50, // default, will be reassigned in created
    }
  },
  watch: {
    error (value) {
      this.isInProgress = !value
    },
  },
  created () {
    this._mt = key => this.$t(`modals.${this.$options.name}.${key}`)

    this.name = this._mt('new')

    const uid = this.currentModalPayload
    if (uid) {
      this.section = sectionsStore.getters.section(uid) || {}
    }

    if (this.section) {
      const { uid, sortOrdering, name } = this.section

      this.name = name
      this.contacts = [...sectionsStore.getters.contactsBySection(uid)]
      this.order = sortOrdering
    }

    const maxLength = window.FEATURES?.max_section_length
    if (maxLength) this.maxLength = maxLength
  },
  computed: {
    currentModalPayload () {
      return uiStore.getters.currentModalPayload
    },
    sections () {
      return Object.values(sectionsStore.state.direct)
        .sort((a, b) => a.sortOrdering - b.sortOrdering)
    },
    normalizedSections () {
      let sections = [...this.sections]

      if (this.section) {
        sections = sections.filter(section => section.uid !== this.section.uid)
      }

      sections = sections.map(section => ({
        name: section.name,
        entities: sectionsStore.getters.contactsBySection(section.uid),
      }))

      sections.push({
        name: this.$t('modals.AddContact.noSection'),
        entities: sectionsStore.getters.contactsBySection(undefined),
      })

      return sections
    },
    memberText () {
      return capitalize(this.$t('glossary.member_plural')) + ':'
    },
    nameHasError () {
      return !this.name.length || this.validateLength(this.name) !== true
    },
  },
  methods: {
    validateLength (input) {
      return (
        input.length <= this.maxLength ||
        this.$t('common.validationErrors.maxLength', { to: this.maxLength })
      )
    },
    handleRelativeOrder (value) {
      this.relativeOrder = value
    },
    async commit () {
      this.error = ''
      this.errorKeys = {}

      let error = await this.updateData()
      if (error) return (this.error = error)

      error = await this.moveSection()
      if (error) return (this.error = error)

      error = await this.updateContacts()
      if (error) return (this.error = error)

      this.close()
    },
    async moveSection () {
      if (!this.relativeOrder || !this.section) return

      const { uid } = this.section
      const { section: relate, after } = this.relativeOrder
      try {
        if (after) {
          await api.sections.moveAfter(uid, relate)
        } else {
          await api.sections.moveBefore(uid, relate)
        }
      } catch (e) {
        return e.error || this.$t('errors.implicitError')
      }
    },
    async updateData () {
      const data = {}
      data.name = this.name
      data.sort_ordering = this.order
      data.color = '#fff'
      data.members = this.contacts.map(item => item.jid)

      try {
        const payload = {
          data,
          type: 'contact',
        }

        if (this.section) {
          payload.uid = this.section.uid
          // TODO: payload should be instance of Section
          this.section = await sectionsStore.actions.edit(payload)
        } else {
          this.section = await sectionsStore.actions.create(payload)
        }
      } catch (e) {
        let { error, details } = e
        if (details) {
          this.errorKeys = details
          Object.keys(details).some(key => {
            return (error = details[key])
          })
        }
        return error || this._mt(this.section ? 'editError' : 'createError')
      }
    },
    async updateContacts () {
      if (!this.section) return

      const { uid } = this.section
      for (const contact of this.contacts) {
        const { jid } = contact
        if (contact.sections.indexOf(uid) !== -1) continue

        const data = { sections: [...contact.sections, uid] }
        try {
          await api.contacts.edit(jid, data)
        } catch (e) {
          return e.error || this.$t('errors.implicitError')
        }
      }
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
