

















































import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Fuse from 'fuse.js'
import { InvitableUser } from '@tada-team/tdproto-ts'
import { invitableUsersStore } from '@/store'
import ItemUser from './ItemUser.vue'
import ItemNoResults from './ItemNoResults.vue'
import LoadErrorSection from './LoadErrorSection.vue'
import { outlinedExpandMore, outlinedClose } from '@quasar/extras/material-icons-outlined'

@Component({
  components: {
    ItemUser,
    ItemNoResults,
    LoadErrorSection,
  },
})
export default class UsersSelector extends Vue {
  @Prop({
    type: Array,
    required: true,
  }) readonly value!: string[]

  /**
   * Use external users (from other nodes) to select from.
   */
  @Prop({
    type: Boolean,
    default: false,
  }) readonly externalUsers!: boolean

  /**
   * Exclude certain team when loading users.
   * Only applicable, when external-users is false/ not-set.
   */
  @Prop({
    type: String,
    default: '',
  }) readonly excludeTeam!: string

  private loading = false
  private users: InvitableUser[] = []
  private options: InvitableUser[] = []
  private loadUsersError = false
  private fuseOptions = getDefaultFuseOptions(['displayName'])
  private dropdownIcon!: string
  private clearIcon!: string

  private get fuseForContacts (): Fuse<InvitableUser> {
    return new Fuse(this.users, this.fuseOptions)
  }

  private get label (): string {
    const t = this.externalUsers
      ? this.$t('modals.AddContact.fromNodes.contactSelectLabel')
      : this.$t('modals.CreationTeam.contactsSelectLabel')
    return t.toString()
  }

  created () {
    this.dropdownIcon = outlinedExpandMore
    this.clearIcon = outlinedClose
    this.setupUsers()
  }

  private async setupUsers (): Promise<void> {
    this.loading = true
    this.loadUsersError = false

    try {
      this.externalUsers
        ? await invitableUsersStore.actions.loadAllExternal(this.excludeTeam)
        : await invitableUsersStore.actions.loadAllInternal(this.excludeTeam)
    } catch (e) {
      this.loadUsersError = true
      this.loading = false
      return
    }

    if (this.externalUsers) {
      this.users = invitableUsersStore.getters.externalSorted
      this.options = invitableUsersStore.getters.externalSorted
    } else {
      this.users = invitableUsersStore.getters.internalSorted
      this.options = invitableUsersStore.getters.internalSorted
    }

    this.loading = false
  }

  private contactSearch (text: string): InvitableUser[] {
    const searchFn = (text: string) => this.fuseForContacts.search(text)
    return multiSearch(searchFn, text, 'uid')
  }

  private filterFn (userInput: string, update: (callback: () => void) => unknown) {
    const val = userInput.trim().toLowerCase()

    // filter only starting with second character
    update(() => {
      this.options = val.length < 2
        ? this.users
        : this.contactSearch(val)
    })
  }

  private handleInput (users: InvitableUser[]): void {
    this.$emit('input', users || [])
  }
}
