//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiStore } from '@/store'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'MemoryUsageModal',
  components: {
    Chart: () => ({
      component: import(/* webpackChunkName: "memoryUsageChart" */ './MemoryUsageChart/index.vue'),
      loading: BaseSpinner,
    }),
  },
  data () {
    return {
      isChartLoading: true,
    }
  },
  computed: {
    data () {
      return uiStore.getters.currentModalPayload
    },
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
  },
}
