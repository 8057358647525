//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDefaultFuseOptions, transformEntityName } from '@/utils'
import { multiSearch } from '@/utils/Common'
import EntityCard from './EntityCard'
import EntityInstanceRow from './EntityInstanceRow'
import Fuse from 'fuse.js'
import ScrollableArea from '@/components/UI/ScrollableArea'
import SearchColoredInput from '@/components/UI/SearchColoredInput'
import {
  contactsStore,
  teamsStore,
  uiStore,
} from '@/store'

export default {
  name: 'EntitySelector',
  components: {
    'search-input': SearchColoredInput,
    'scrollable-area': ScrollableArea,
    IconUserPlus: () => import('@/components/UI/icons/IconUserPlus'),
    IconCheckCircle: () => import('@/components/UI/icons/IconCheckCircle'),
    EntityInstanceRow,
    EntityCard,
  },
  data () {
    return {
      filter: '',
      selectMap: {},

      listHeight: 0,

      showLimitAlert: false,

      fuseOptions: getDefaultFuseOptions(['displayName']),
    }
  },
  computed: {
    computedAcceptLabel () {
      const label = this.$options.acceptLabel
      const computedLabel = typeof label === 'function' ? (label(Object.keys(this.selectMap)) || '') : label
      return transformEntityName(computedLabel)
    },
    teamMembers () {
      return contactsStore.getters.teamMembers
    },
    sourceList () {
      const { sourceList, filterPredicate, sortPredicate } = this.$options

      let result = sourceList || this.teamMembers
      result = filterPredicate ? result.filter(filterPredicate) : result

      let archiveList = []
      let list = []

      result.forEach(entity => {
        entity.isArchive ? archiveList.push(entity) : list.push(entity)
      })

      const nameSortPredicate = (a, b) => a.displayName > b.displayName ? 1 : -1

      archiveList = archiveList.sort(nameSortPredicate)
      archiveList = sortPredicate ? archiveList.sort(sortPredicate) : archiveList

      list = list.sort(nameSortPredicate)
      list = sortPredicate ? list.sort(sortPredicate) : list

      result = list.concat(archiveList)

      const { preselected } = this.$options
      if (preselected.length > 0) {
        const preselectedIndexes = []

        result.forEach((entity, index) => {
          const { jid } = entity
          const isPreselected = preselected.indexOf(jid) >= 0
          isPreselected && preselectedIndexes.push(index)
        })

        preselectedIndexes.forEach(index => {
          const temp = result[index]
          result.splice(index, 1)
          result.unshift(temp)
        })
      }

      if (
        !this.filter ||
          this.filter?.length < 2
      ) return result

      const fuse = new Fuse(result, this.fuseOptions)
      const text = this.filter
      return multiSearch(text => fuse.search(text), text, 'jid')
    },
    sourceListStyle () {
      return { 'max-height': `${this.$options.entityRowHeight * this.$options.maxRowsToDisplay}px` }
    },
    selectedCount () {
      return Object.keys(this.selectMap).length
    },
    disabledAccept () {
      return this.selectedCount <= 0
    },
    canAddToTeam () {
      const { canAddToTeam } = teamsStore.getters.currentTeam.me
      return canAddToTeam
    },
  },
  created () {
    const params = uiStore.getters.currentModalPayload || {}

    const defaultValues = {
      caption: this.$t('modals.EntitySelector.inviteMembers'),
      sourceList: [],
      limit: null,
      acceptLabel: this.$t('modals.EntitySelector.invite'),
      acceptCallback: null,
      rejectLabel: this.$t('modals.EntitySelector.cancel'),
      rejectCallback: null,
      sortPredicate: null,
      filterPredicate: null,
      singlePick: false,
      preselected: [],
    }

    Object.assign(this.$options, defaultValues, params)

    this.$options.entityRowHeight = 56
    this.$options.maxRowsToDisplay = 6

    this.listPadding = 4
    this.listHeight = this.minListHeight = this.listPadding + 28
    this.maxListHeight = 112

    this.$options.preselected = Array.isArray(this.$options.preselected) ? this.$options.preselected : []
    this.$options.preselected.forEach(this.toggleEntity)
  },
  mounted () {
    this.calculateListHeight()
  },
  updated () {
    this.calculateListHeight()
  },
  methods: {
    invite () {
      this.close()

      this.$nextTick(() => {
        window['@@EntitySelector'] = 'await'

        uiStore.actions.showModal({ instance: 'AddContact' })
      })
    },
    calculateListHeight () {
      const { scrollableArea } = this.$refs
      if (!scrollableArea) return

      const contentHeight = scrollableArea.getContentHeight()

      this.listHeight = Math.max(Math.min(contentHeight, this.maxListHeight), this.minListHeight)
    },
    toggleEntity (jid) {
      const exist = this.selectMap[jid]
      if (exist) {
        this.showLimitAlert = false
        this.$delete(this.selectMap, jid)
        return
      }

      const { getters } = this.$store
      const entity = getters.entity(jid)
      if (!entity) return

      if (this.$options?.limit === Object.keys(this.selectMap).length) {
        this.showLimitAlert = true
        return
      }

      if (this.$options.singlePick) {
        this.selectMap = {}
      }

      this.$set(this.selectMap, jid, entity)
    },
    accept () {
      if (this.disabledAccept) return

      const { acceptCallback } = this.$options
      acceptCallback && acceptCallback(Object.keys(this.selectMap))

      this.close()
    },
    close () {
      const { rejectCallback } = this.$options
      rejectCallback && rejectCallback()

      uiStore.actions.hideModal()
    },
  },
}
