//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { extend } from 'quasar'
import { transformEntityName } from '@/utils'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

import { sectionsStore, uiStore } from '@/store'

export default {
  components: {
    BaseSpinner,
  },
  data: () => ({
    section: {
      uid: null,
      name: '',
      description: '',
      deadline: null,
    },

    err: null,
    inProgress: false,
    maxLength: 50, // default, will be reassigned in created
  }),
  computed: {
    originSection () {
      return this.payload?.uid && sectionsStore.getters.section(this.payload.uid, 'task')
    },
    payload () {
      return uiStore.getters.currentModalPayload
    },
    error () {
      return this.err?.details || {}
    },
    inputHasError () {
      const hasServerError = this.error.name && this.error.name.length > 0
      const hasClientError = this.validateLength(this.section.name) !== true
      return hasServerError || hasClientError
    },
  },
  created () {
    if (this.payload?.uid) {
      const section = sectionsStore.getters.section(this.payload.uid, 'task')
      this.section = extend(true, {}, section)
    }
    const maxLength = window.FEATURES?.max_section_length
    if (maxLength) this.maxLength = maxLength
  },
  methods: {
    onInputName () {
      this.err = null
    },
    validateLength (input) {
      return (
        input.length <= this.maxLength ||
        this.$t('common.validationErrors.maxLength', { to: this.maxLength })
      )
    },
    async remove () {
      uiStore.actions.showModal({
        instance: 'universal-yes-no',
        payload: {
          title: this.$t('modals.EditSection.deleteTitle'),
          text: this.$t('modals.EditSection.deleteText', {
            name: transformEntityName(this.originSection.name),
          }),
          yesText: this.$t('common.delete'),
          yes: async () => {
            await sectionsStore.actions.delete({
              uid: this.section.uid,
              type: 'task',
            })
            this.close()
          },
        },
      })
    },
    async save () {
      if (this.section.name.length === 0) {
        this.$refs.name.$refs.input.focus()
        return
      }

      this.inProgress = true

      const data = {
        name: this.section.name,
        sort_ordering: this.section.order,
        description: this.section.description,
        deadline: this.section.deadline,
      }

      const payload = {
        data,
        type: 'task',
      }

      try {
        if (this.section.uid) {
          payload.uid = this.section.uid
          // TODO: payload should be instance of Section
          this.section = await sectionsStore.actions.edit(payload)
        } else {
          this.section = await sectionsStore.actions.create(payload)
          uiStore.actions.setModalResult(this.section.uid)
        }
        this.close()
      } catch (e) {
        this.err = e
      }
      this.inProgress = false
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
