//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ViewerTape',
  props: {
    value: {
      type: Number,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tapeX: 0,
    }
  },
  watch: {
    value () {
      this.$nextTick(() => {
        this.moveTape()
      })
    },
  },
  created () {
    this.ready = false
  },
  mounted () {
    this.moveTape()
    this.$nextTick(() => { this.ready = true })
  },
  methods: {
    imageURL (content) {
      const { previewURL, preview2xURL, mediaURL } = content
      const preferHighResolution = (window.devicePixelRatio || 1) > 1
      return (preferHighResolution && preview2xURL) || previewURL || mediaURL
    },
    moveTape (index) {
      index = this.value

      const { list } = this.$refs
      if (!list) return

      const previewElement = list.children[index]
      if (!previewElement) return

      const { offsetWidth } = list
      const { clientWidth, offsetLeft } = previewElement
      this.tapeX = offsetWidth - (offsetLeft + clientWidth / 2)
    },
    emit (index) {
      this.$emit('input', index)
    },
  },
}
