export default {
  mounted () {
    this.fdmEvents = {
      dragenter: () => {
        const { fileInputLabel } = this.$refs
        fileInputLabel && fileInputLabel.classList.add('active')
      },
      dragleave: () => {
        const { fileInputLabel } = this.$refs
        fileInputLabel && fileInputLabel.classList.remove('active')
      },
      dragover: event => { event.preventDefault() },
      drop: event => {
        event.preventDefault()

        this.fdmEvents.dragleave()

        const { files } = event.dataTransfer
        if (files.length === 0) return

        const file = files[0]
        this.handleFile && this.handleFile(file)
      },
    }

    const { fileInputLabel } = this.$refs
    if (!fileInputLabel) return

    Object.keys(this.fdmEvents).forEach(eventName => {
      fileInputLabel.addEventListener(eventName, this.fdmEvents[eventName])
    })
  },
  beforeDestroy () {
    const { fileInputLabel } = this.$refs
    if (!fileInputLabel) return

    Object.keys(this.fdmEvents).forEach(eventName => {
      fileInputLabel.removeEventListener(eventName, this.fdmEvents[eventName])
    })
  },
}
