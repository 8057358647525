//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NameInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    dynamicPlaceholderSize: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 290,
    },
    error: String,
    type: {
      type: String,
      default: 'text',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 20,
    },
    center: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      focused: false,
    }
  },
  computed: {
    inputMaskValue () {
      if (this.type !== 'tel') return null

      if (!this.value) {
        if (this.focused) {
          const mask = this.defaultPhoneCodes[0] + this.defaultPhoneMask
          return { mask }
        }
        return null
      }

      const { input } = this.$refs
      if (!input) return null

      let codeIndex = -1
      this.defaultPhoneCodes.some((code, index) => {
        const match = this.value.indexOf(code) === 0
        if (match) {
          codeIndex = index
          return true
        }
      })

      if (codeIndex < 0) return null

      const mask = this.defaultPhoneCodes[codeIndex] + this.defaultPhoneMask

      let { value } = input

      value = value || this.value
      if (mask.length === value.length) return null

      return {
        mirror: value,
        mask: mask.slice(value.length),
      }
    },
    componentStyle () {
      const result = { width: `${this.width}px` }
      // this.fontSize && Object.assign(result, { paddingBottom: `${this.fontSize - 6}px` })
      this.fontSize && Object.assign(result, { paddingBottom: '4px' })

      this.dynamicPlaceholderSize && Object.assign(result, { paddingTop: `${this.dynamicPlaceholderSize * 2}px` })

      return result
    },
    inputStyle () {
      if (!this.fontSize) return

      const style = { fontSize: `${this.fontSize}px`, lineHeight: `${this.fontSize + 4}px` }
      this.center && Object.assign(style, { 'text-align': 'center' })
      return style
    },
    activeDynamicPlaceholder () {
      return this.dynamicPlaceholderSize && (!!this.value || this.focused)
    },
    dynamicPlaceholderStyle () {
      if (!this.dynamicPlaceholderSize) return

      let specificStyle = null
      if (this.activeDynamicPlaceholder) {
        specificStyle = { fontSize: `${this.dynamicPlaceholderSize}px`, fontWeight: '500', lineHeight: `${this.dynamicPlaceholderSize + 3}px`, top: '0', left: this.center ? '50%' : '0' }
      } else {
        const top = (this.dynamicPlaceholderSize * 2)
        specificStyle = { fontSize: `${this.fontSize}px`, lineHeight: this.inputStyle.lineHeight, top: `${top}px` }
      }

      return specificStyle
    },
  },
  watch: {
    error (value) {
      value && this.$nextTick(this.focus)
    },
  },
  created () {
    this.defaultPhoneCodes = ['+7', '8']
    this.defaultPhoneMask = ' --- --- -- --'
  },
  mounted () {
    this.autofocus && this.focus()
  },
  methods: {
    input (event) {
      const { target } = event

      const isPhoneInput = target.getAttribute('type') === 'tel'
      this.inputText(target.value, isPhoneInput)
    },
    focus () {
      const { input } = this.$refs
      input && input.focus()
    },
    inputText (value, isPhoneInput) {
      if (!isPhoneInput) {
        this.$emit('input', value)
        return
      }

      value = value.replace(/[^0-9+()\-\s]+/g, '')
      this.$emit('input', value)
    },
    handleFocus (e) {
      const isFocusEvent = e.type === 'focus'
      this.focused = isFocusEvent

      if (this.type === 'tel' && isFocusEvent && !this.value) {
        this.$nextTick(() => {
          this.inputText('+7', true)

          const { input } = this.$refs
          if (!input) return

          const { value } = input
          input.setSelectionRange(value.length, value.length)
        })
      }
    },
  },
}
