import Quill from 'quill'

import * as Keyboard from '@/components/Chat/ReplyArea/EditableArea/Keyboard'
import * as Utils from '@/components/Chat/ReplyArea/EditableArea/Utils'
import { Formats } from '@/components/Chat/ReplyArea/EditableArea/Instance'

export default class {
  quill: Quill
  element: HTMLElement

  constructor ({ element, placeholder, onTextChange }: { element: HTMLElement; placeholder: string; onTextChange?: (delta: any, oldDelta: any, source: string) => void }) {
    this.element = element

    this.quill = new Quill(this.element, {
      modules: {
        toolbar: false,
        keyboard: {
          bindings: Keyboard.getBindings(),
        },
      },
      formats: [Formats.EMOJI, Formats.MENTION],
    })

    this.setPlaceholder(placeholder)

    Utils.focusArea(this.quill, true)

    if (onTextChange) {
      this.quill.on('text-change', onTextChange)
    }
  }

  public getContent = () => Utils.splitContentToMessages(this.quill)

  public destroy = () => {

  }

  public setPlaceholder = (placeholder: string) => {
    const { root } = this.quill
    root && root.setAttribute('data-placeholder', placeholder)
  }
}
