//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { transformEntityName } from '@/utils'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { Contact, Chat } from '@tada-team/tdproto-ts'
import { contactsStore, groupsStore } from '@/store'

export default {
  name: 'EntityCard',
  components: {
    BaseEntityAvatar,
    'close-icon': createSVGComponent({ icon: 'close', size: 14, sizeStorage: 16 }),
  },
  props: {
    source: {
      type: [Object, String],
      required: true,
    },
    height: {
      type: Number,
      default: 28,
    },
    maxWidth: {
      type: Number,
      default: 250,
    },
  },
  computed: {
    entity () {
      const { getters } = this.$store
      const result = typeof this.source === 'string' ? getters.entity(this.source) : this.source
      return result || {}
    },
    icon () {
      const entity = this.entity
      if (entity instanceof Contact) { return contactsStore.getters.contactIcon(entity.jid) } else if (entity instanceof Chat) {
        return groupsStore.getters.icon(entity.jid, true)
      }
      return entity.icon
    },
    name () {
      return transformEntityName(this.entity.displayName)
    },
  },
}
