//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoAvailablePlacesPic from './NoAvailablePlacesPic.vue'

export default {
  name: 'NoAvailablePlacesWarning',
  components: {
    NoAvailablePlacesPic,
  },
}
