import store, {
  contactsStore,
  teamsStore,
  uiStore,
} from '@/store'
import i18n from '@/i18n'
import router from '@/router'

export const openSelectionOfTeamMembers = (options?: any) => {
  const sourceList = null // getters.teamMembers

  const { hideArchivedUsers } = teamsStore.getters.currentTeam

  const filterPredicate = (entity: TADA.Entity) => {
    const { getters } = store
    if (entity.jid === getters.getUserId) return false
    if (hideArchivedUsers && (entity as any).isArchive) return false

    // const chat = getters.directChats[entity.jid]
    // if (chat && !chat.hidden) return false

    return true
  }

  const acceptLabel = (list: Array<string>) => {
    const result = i18n.t('chatlist.createDialog').toString()
    if (!list || list.length === 0) return result

    const jid = list[0]

    const contact = contactsStore.getters.contact(jid)

    if (!contact) return result

    return i18n.t('chatlist.createDialogWith', { name: contact.displayName }).toString()
  }

  const acceptCallback = (list: Array<string>) => {
    if (!list || list.length === 0) return
    router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: list[0],
      },
    })
  }

  const payload = {
    id: 'DirectChatCreation',
    caption: i18n.t('chatlist.createChat').toString(),
    singlePick: true,
    sourceList,
    filterPredicate,
    acceptLabel,
    acceptCallback,
  }

  options && Object.assign(payload, options)

  uiStore.actions.showModal({
    instance: 'EntitySelector',
    payload,
  })
}
