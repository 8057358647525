//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scrollIntoViewIfNeeded, transformEntityName } from '@/utils'
import ScrollableArea from '@/components/UI/ScrollableArea'
import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { sectionsStore } from '@/store'

export default {
  name: 'SortableList',
  components: {
    'scrollable-area': ScrollableArea,
    'move-icon': createSVGComponent({ icon: 'canmove', size: 14, permanentlyActive: true }),
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      tempOrder: -1,
      draggingItem: null,
    }
  },
  computed: {
    tempList () {
      const list = Object.keys(sectionsStore.state.direct)
        .filter(key => key !== this.uid)

      return list
    },
    sectionList () {
      const list = [...this.tempList].map((key, index) => {
        const section = sectionsStore.state.direct[key]
        const { uid, name } = section
        return { uid, name, order: index }
      })

      list.splice(this.tempOrder, 0, {
        uid: this.uid,
        name: null,
        order: this.tempOrder,
      })

      for (let i = this.tempOrder + 1; i < list.length; i++) {
        list[i].order += 1
      }

      return list
    },
    listHeight () {
      return Math.min(this.sectionList.length, this.maxDispalyed) * (this.itemHeight + this.itemTopMargin) - this.itemTopMargin
    },
  },
  created () {
    this.tempOrder = this.value

    this.itemHeight = 32
    this.itemTopMargin = 2
    this.maxDispalyed = 5

    this.handleWindowKeydown = event => {
      const key = event.key
      if (key !== 'ArrowDown' && key !== 'ArrowUp') return

      const toUp = key === 'ArrowUp'
      const max = this.sectionList.length - 1

      const value = this.tempOrder + (toUp ? -1 : 1)
      this.tempOrder = Math.max(Math.min(max, value), 0)

      this.$emit('input', this.tempOrder)
      this.emitRelativeOrder()

      const target = this.$el && this.$el.querySelector(`li[data-order="${this.tempOrder}"`)
      target && scrollIntoViewIfNeeded(target)
    }
    window.addEventListener('keydown', this.handleWindowKeydown)
  },
  mounted () {
    const { scrollableArea } = this.$refs
    if (scrollableArea) {
      let offset = this.tempOrder * (this.itemHeight + this.itemTopMargin) - this.itemTopMargin
      offset -= this.listHeight / 2
      offset += this.itemHeight
      scrollableArea.scrollTo(offset)
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleWindowKeydown)
    window.removeEventListener('mouseup', this.handleMouseUp)
  },
  methods: {
    emitRelativeOrder () {
      if (this.sectionList.length === 0) return null

      const sign = this.tempOrder === 0 ? 1 : -1
      const section = this.sectionList[this.tempOrder + sign]
      if (!section) return

      const value = { after: sign < 0, section: section.uid }
      this.$emit('relativeOrder', value)
    },
    handleMouseDown (event) {
      // event.preventDefault()

      const item = event.target
      if (!item || item.tagName.toLowerCase() !== 'li' || item.className.indexOf('--target') < 0) return

      this.draggingItem = item

      window.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseUp () {
      if (this.draggingItem) {
        this.$emit('input', this.tempOrder)
        this.emitRelativeOrder()
        this.draggingItem = null
      }

      window.removeEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseMove (event) {
      if (!this.draggingItem) return

      const itemBelow = event.target
      if (!itemBelow || itemBelow.tagName.toLowerCase() !== 'li') return

      const order = itemBelow.getAttribute('data-order')
      this.tempOrder = +order
    },
    transformName (value) {
      return transformEntityName(value === null ? this.name : value)
    },
  },
}
