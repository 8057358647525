//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dataURItoBlob } from '@/utils/DataUtils'
import FileDropMixin from './FileDropMixin'

import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { Events, DialogEventBus } from '@/components/Modals/XModals/DialogEventBus'

export default {
  name: 'AvatarUploader',
  mixins: [FileDropMixin],
  components: {
    'upload-icon': createRawSVGComponent({ icon: 'upload', size: 30, sizeStorage: 48, permanentlyActive: true }),
    'close-icon': createRawSVGComponent({ icon: 'close', size: 12, sizeStorage: 16, permanentlyActive: true }),
  },
  props: {
    size: Number,
    defaultImage: String,
    entityType: String,
  },
  data () {
    return {
      image: this.defaultImage,
    }
  },
  computed: {
    sizeStyle () {
      const size = this.size || 120
      return { width: `${size}px`, height: `${size}px` }
    },
  },
  created () {
    this.placeholder = '/assets/i/placeholders/photo.svg'
    this.types = ['image/jpeg', 'image/png', 'image/gif']
  },
  mounted () {
    this.cropperHandler = ({ data }) => {
      this.image = data || this.defaultImage
      this.$emit('input', dataURItoBlob(this.image))
    }
    DialogEventBus.$on(Events.SEND_DATA_FROM_DIALOG, this.cropperHandler)
  },
  beforeDestroy () {
    DialogEventBus.$off(Events.SEND_DATA_FROM_DIALOG, this.cropperHandler)
  },
  methods: {
    reset () {
      const { fileInputLabel, fileInput } = this.$refs
      fileInputLabel && fileInputLabel.removeAttribute('title')
      fileInput && (fileInput.value = '')

      this.image = null
      this.$emit('input', this.image)
    },
    select (event) {
      const input = event.target || event.currentTarget
      const file = input.files && input.files[0]
      this.handleFile(file)
    },
    handleFile (file) {
      if (!file || file.type === '' || this.types.indexOf(file.type) === -1) return

      const { name } = file

      const { fileInputLabel, fileInput } = this.$refs
      fileInputLabel && fileInputLabel.setAttribute('title', name)
      fileInput && (fileInput.value = '')

      const reader = new window.FileReader()
      reader.onload = e => {
        this.image = e.target.result
        DialogEventBus.$emit(Events.OPEN_DIALOG, {
          instance: 'avatar-cropper', data: this.image,
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
