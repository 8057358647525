//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollableArea from '@/components/UI/ScrollableArea'
import { uiStore } from '@/store'

export default {
  name: 'UniversalYesNo',
  components: {
    'scrollable-area': ScrollableArea,
  },
  props: ['data'],
  data () {
    return {
      inProcess: false,
      report: '',
    }
  },
  computed: {
    payload () {
      return this.data || uiStore.getters.currentModalPayload
    },
  },
  created () {

  },
  mounted () {
    const { content, scrollableArea, contentWrapper } = this.$refs
    if (!contentWrapper) return

    let { html } = this.payload
    if (!html) return

    if (typeof html === 'function') {
      html = html(contentWrapper)
    }

    const isValidHTML = html instanceof window.HTMLElement || html instanceof window.DocumentFragment
    if (!isValidHTML) return

    content.appendChild(html)

    contentWrapper.style.height = contentWrapper.clientHeight + 'px'
    this.$nextTick(() => { scrollableArea.update() })
    scrollableArea.scrollTop()
  },
  methods: {
    async yes () {
      this.inProcess = true
      this.report = ''
      // мб сделать ошибки через reject
      // мб кстати, да
      try {
        await this.payload.yes()
      } catch (e) {
        this.inProcess = false
        this.report = e.message || JSON.stringify(e)
        return
      }
      this.inProcess = false
      if (this.data) return

      const instance = this.payload?.nextModal?.instance
      if (instance) {
        const payload = this.payload.nextModal.payload
        uiStore.actions.showModal({ instance, payload })
      } else {
        uiStore.actions.hideModal()
      }
    },
    close () {
      if (this.payload.no) {
        this.payload.no()
      } else {
        uiStore.actions.hideModal()
      }
    },
  },
}
