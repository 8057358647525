//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InstructionItem',
  props: {
    imgSRC: String,
    imgAlt: {
      type: String,
      default: '',
    },
    imgWidth: {
      type: String,
      default: '600px',
    },
    itemText: {
      type: String,
      default: '',
    },
  },
}
