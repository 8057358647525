//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAspectRatioSize } from '@/utils/Common'

import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import MoveImageMixin from './MoveImageMixin'

export default {
  name: 'ImageView',
  mixins: [MoveImageMixin],
  components: {
    'fit-img': createRawSVGComponent({ icon: 'fit-img' }),
    'zoom-in': createRawSVGComponent({ icon: 'zoom-in' }),
    'zoom-out': createRawSVGComponent({ icon: 'zoom-out' }),
    'rotate-cw': createRawSVGComponent({ icon: 'rotate-cw' }),
    'rotate-ccw': createRawSVGComponent({ icon: 'rotate-ccw' }),

    BaseSpinner,
  },
  props: ['file', 'fullscreen'],
  data () {
    return {
      ready: false,

      width: 0,
      height: 0,
      zoomWidth: 0,
      zoomHeight: 0,
      rotate: 0,

      maxImageWidth: 0,
      maxImageHeight: 0,

      zoom: 1,

      expandMode: false,
      fitStateIcon: null,
      copyLoading: false,
    }
  },
  watch: {
    file () {
      this.ready = false

      this.width = this.height = 0
      this.zoomWidth = this.zoomHeight = 0
      this.zoom = 1
    },
    fullscreen (value) {
      this.calcMaxSizes()

      !value && this.fitImageToPreview()
    },
  },
  created () {
    this.setIconFitState()

    this.$watch(vm => [vm.width, vm.height, vm.zoom].join(), this.toggleMoveMode)
  },
  mounted () {
    this.calcMaxSizes()

    window.addEventListener('keydown', this.onKey)
    window.addEventListener('DOMMouseScroll', this.handleWheel)
    window.addEventListener('mousewheel', this.handleWheel)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKey)
    window.removeEventListener('DOMMouseScroll', this.handleWheel)
    window.removeEventListener('mousewheel', this.handleWheel)
  },
  methods: {
    onKey (event) {
      console.warn(event)
      if (event.ctrlKey || event.metaKey) {
        if (event.code === 'KeyC' || event.which === 67) {
          this.copy()
        }
      }
    },
    async copy () { // #2994, https://web.dev/image-support-for-async-clipboard/
      try {
        this.copyLoading = true

        const imgURL = this.file.mediaURL || this.file.content
        const data = await fetch(imgURL, { credentials: 'include' })
        const blob = await data.blob()
        await navigator.clipboard.write([
          new window.ClipboardItem(Object.defineProperty({}, blob.type, {
            value: blob,
            enumerable: true,
          })),
        ])
        console.log('Image copied.')
      } catch (e) {
        console.error(e, e.message)
      }
      this.copyLoading = false
    },
    calcMaxSizes () {
      const { offsetWidth, offsetHeight } = this.$el

      this.maxImageWidth = offsetWidth
      this.maxImageHeight = offsetHeight
    },
    handleWheel (event) {
      let delta = 0
      if (event.wheelDelta) delta = event.wheelDelta / 120
      else if (event.detail) delta = -event.detail / 3

      this.zoomImage(delta)
    },
    handleImageLoad () {
      if (this.ready) return

      const { subject } = this.$refs
      if (!subject) return

      const { naturalWidth, naturalHeight } = subject

      this.naturalWidth = naturalWidth
      this.naturalHeight = naturalHeight

      this.expandMode ? this.expandImage() : this.fitImageToPreview()

      this.ready = true
    },
    toggleExpandMode () {
      if (!this.ready) return

      this.expandMode ? this.fitImageToPreview() : this.expandImage()
      this.expandMode = !this.expandMode

      this.setIconFitState()

      const { toggleFullscreen } = this.$parent
      toggleFullscreen && toggleFullscreen(this.expandMode)
    },
    expandImage () {
      this.zoom = 1

      this.width = this.naturalWidth
      this.height = this.naturalHeight
      this.zoomWidth = this.zoomHeight = 0

      if (this.width > this.$el.clientWidth) {
        this.x = (this.$el.clientWidth - this.width) / 2
      }
    },
    fitImageToPreview () {
      const { width, height } = getAspectRatioSize({
        maxWidth: this.maxImageWidth,
        maxHeight: Math.min(this.maxImageHeight / 1.25, this.maxImageHeight - 180),
        width: this.naturalWidth,
        height: this.naturalHeight,
      })

      this.zoom = 1
      this.width = this.naturalWidth < width ? this.naturalWidth : width
      this.height = this.naturalHeight < height ? this.naturalHeight : height
      this.zoomWidth = this.zoomHeight = 0
      this.x = 0
    },
    zoomImage (sign) {
      if (!this.ready) return

      const step = 0.25

      const zoom = this.zoom + (step * sign)
      this.zoom = Math.min(Math.max(step, zoom), 3)

      if (this.zoom === 1) {
        this.zoomWidth = this.zoomHeight = 0
        return
      }

      this.zoomWidth = this.width * this.zoom
      this.zoomHeight = this.height * this.zoom

      if (this.zoomWidth > this.$el.clientWidth) {
        this.x = (this.$el.clientWidth - this.zoomWidth) / 2
      }
    },
    rotateImage (deg) {
      if (!this.ready) return

      this.rotate += deg
      if (this.rotate === 360 || this.rotate === -360) {
        this.rotate = 0
      }

      const isLandscape = !!Math.abs(this.rotate % 180)
      const aspectRatio = this.width / this.height
      const containerHeight = this.$el.clientHeight
      const containerWidth = this.$el.clientWidth

      if (isLandscape && this.width > containerHeight) {
        this.width = containerHeight
        this.height = containerHeight / aspectRatio
      } else if (isLandscape && this.height > containerWidth) {
        this.width = containerWidth * aspectRatio
        this.height = containerWidth
      } else {
        this.fitImageToPreview()
      }
    },
    handleViewClick (event) {
      const { currentTarget, target } = event
      if (currentTarget !== target) return

      const closeFunction = this.$parent.close
      closeFunction && closeFunction()
    },
    setIconFitState (fit) {
      fit = fit || !this.expandMode
      const prefix = fit ? 'fit' : 'unfit'
      this.fitStateIcon = this[`${prefix}IconCache`] || (this[`${prefix}IconCache`] = createRawSVGComponent({ icon: prefix + '-img' }))
    },
    async toggleMoveMode () {
      if (!this.ready) return

      await this.$nextTick()

      const { subject } = this.$refs
      if (!subject) return

      const { width, height } = subject.getBoundingClientRect()
      const overflow = width > this.maxImageWidth || height > this.maxImageHeight
      this.moveState = +overflow

      this.toggleEvents(overflow)

      if (!overflow) {
        this.x = this.y = 0
      }
    },
  },
}
