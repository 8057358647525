//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAspectRatioSize } from '@/utils/Common'

import { Events, DialogEventBus } from '../../XModals/DialogEventBus'
import CropperOverlay from '../../AvatarCropper/CropperOverlay'
import { uiStore } from '@/store'

export default {
  name: 'AvatarCropper',
  components: {
    overlay: CropperOverlay,
  },
  data () {
    return {
      ready: false,
      sourceImageWidth: 0,
      sourceImageHeight: 0,
      offsetX: 0,
      offsetY: 0,

      result: null,
    }
  },
  watch: {
    source () {
      this.ready = false
    },
  },
  created () {
    this.width = 500
    this.height = 350

    this.placeholder = '/assets/i/placeholders/photo.svg'

    const { currentModalPayload: payload } = uiStore.getters
    this.data = payload && payload.data
  },
  methods: {
    setupImageAttributes () {
      if (!this.data) {
        this.ready = true
        return
      }

      const { sourceImage } = this.$refs
      if (!sourceImage) return

      const { naturalWidth, naturalHeight } = sourceImage

      this.sourceImageNaturalWidth = naturalWidth
      this.sourceImageNaturalHeight = naturalHeight

      if (naturalWidth > this.width || naturalHeight > this.height) {
        const { width, height } = getAspectRatioSize({
          maxWidth: this.width,
          maxHeight: this.height,
          width: naturalWidth,
          height: naturalHeight,
        })

        this.sourceImageWidth = width
        this.sourceImageHeight = height
      } else {
        this.sourceImageWidth = this.sourceImageNaturalWidth
        this.sourceImageHeight = this.sourceImageNaturalHeight
      }

      this.offsetX = Math.round((this.width - this.sourceImageWidth) / 2)
      this.offsetY = Math.round((this.height - this.sourceImageHeight) / 2)

      this.ready = true
    },
    crop (x, y, w, h) {
      const { canvas, sourceImage } = this.$refs
      canvas.width = w
      canvas.height = h
      const ctx = canvas.getContext('2d')

      ctx.drawImage(sourceImage, x, y, w, h, 0, 0, w, h)
      this.result = canvas.toDataURL('image/png')
    },
    commit () {
      if (!this.data) return

      const { overlay } = this.$refs
      let { x, y, width, height } = overlay.getFrameRectangle()

      const aH = this.sourceImageNaturalWidth / this.sourceImageWidth
      const aV = this.sourceImageNaturalHeight / this.sourceImageHeight

      x *= aH
      width *= aH

      y *= aV
      height *= aV

      this.crop(x, y, width, height)

      this.close()
    },
    close () {
      this.emit()

      uiStore.actions.hideModal()
    },
    emit () {
      DialogEventBus.$emit(Events.SEND_DATA_FROM_DIALOG, {
        data: this.result,
      })
    },
  },
}
